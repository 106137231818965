.table {
  width: 100%;
  margin-top: 30px;

  tr {
    text-align: center;

    &:nth-child(even) {
      background-color: #dedede;
    }
  }

  th {
    background-color: #dedede;
  }

  td,
  th {
    padding: 10px;
  }
}
.small-field{
  width: 100%;
  .mat-error{
    font-size: 11px;
  }
  .date-range-wrapper .mat-date-range-input-container{
    margin-top: 3px;
  }
  .mat-select-arrow-wrapper{
    height: auto;
  }
  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper{
    padding: 0;
  }
  .date-range-wrapper .mat-form-field-empty.mat-form-field-label, .date-range-wrapper .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label{
    margin-top: -3px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-label{
    margin-top: -0.6em;
  }
  .mat-form-field-should-float .mat-form-field-label{
    margin-top: -0.1em;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex{
    height: 40px;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix{
    padding: 0.6em 0 1em 0;
  }
  .mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix{
    font-size: 15px;
  }
}
.textarea{
  .mat-form-field-appearance-outline .mat-form-field-flex{
    height: auto !important;
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    background: white;
    border-radius: 0.3rem;
  }

  .mat-form-field-infix {
    border-top-width: 4px;
  }


  .mat-form-field-suffix {
    top: 4px !important;
  }
}

.mat-slide-toggle-bar {
  height: 22px !important;
  border-radius: 10px !important;
}

.mat-slide-toggle-thumb-container {
  top: 1px !important;
}

.no-label {
  .mat-select-arrow-wrapper {
    transform: translateY(0%) !important;
  }
  .mat-form-field-infix {
    padding-bottom: 12px !important;
    border-top: 4px solid transparent;
    padding-left: 3px !important;
  }
}

.select-default, .default-field {
  .mat-form-field-flex {
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px !important;
    padding: 0.45em 0.45em 0 0.45em;
  }
  .mat-form-field-infix {
    padding: 0.25em 0 0.45em 0;
  }
  .mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
    padding: 0.45em 0.45em 0 0.45em;
    border-radius: 4px;
  }
  .mat-form-field-underline {
    width: 0px !important;
    display: none;
  }
  .mat-slide-toggle-thumb {
    width: 22px !important;
    height: 22px !important;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(29px, 0, 0) !important;
  }
  .mat-slide-toggle-bar {
    height: 24px !important;
    width: 52px !important;
    border-radius: 39px !important;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #fff !important;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #00a3ff !important;
  }
  &.outline {
    .mat-form-field-flex {
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .mat-select-arrow-wrapper{
    color: #00000080;
  }
  &-small {
    .mat-form-field-flex {
      padding: 0px 12px !important;
    }
    .mat-form-field-infix{
      padding: 9px 0 !important;
    }
  }
}

.default-field {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-form-field-flex {
    height: 42px;
    padding-top: 5px !important;
  }
}

mat-form-field {
  &.no-error {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
    .mat-form-field-wrapper {
      margin: 0;
    }
    .mat-form-field-outline-start, .mat-form-field-outline-end {
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mat-form-field-outline-start {
      border-right: none;
    }
    .mat-form-field-outline-end {
      border-left: none;
    }
  }
}

.like-field {
  padding: 0.75em 0.75em 0 0.75em;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px !important;
  height: 42px;
}

.set-categories-wrap {
  min-width: 390px !important;
  max-width: unset !important;
  max-height: 500px !important;
  overflow: auto !important;
  .mat-menu-content {
    height: 100%;
  }
  .mat-menu-content > div {
    height: 100%;
  }
}
.set-categories-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}
