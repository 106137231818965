.container {
  display: flex;

  padding: 40px;

  &_column {
    flex-direction: column;
  }

  &_center {
    justify-content: center;
  }
}

.list-container {
  margin: 25px 20px;
  position: sticky;
  top: 25px;
}

.default-tabs {
  background-color: #e2e2e2;
  border: none;
  padding: 0 14px;
  mat-ink-bar {
    background-color: #ffffff !important;
  }
  .mat-tab-label-active {
    background-color: #cecece;
  }
  .mat-tab-link {
    color: black;
    min-width: fit-content;
    text-transform: uppercase;
  }
}

.page-container {
  max-width: 1152px;
  padding: 0 24px;
  padding-top: 32px;
  margin: 0px auto;
}

.border {
  border: 1px solid #e2e2e2;
}

.background-opacity-white {
  background: rgba(255, 255, 255, 0.7);
}
