@import "variables";

.w-full {
  width: 100%;
}
.w-75 {
  width: 75%;
}
.w-50 {
  width: 50%;
}
.w-33 {
  width: 33%;
}
.w-1\4 {
  width: 25%;
}
.h-full {
  height: 100%;
}
.h-full-without-header-footer {
  height: calc(100% - 48px - 80px);
}
.min-h-full {
  min-height: 100%;
}
.min-w-unset {
  min-width: unset !important;
}
.m-auto{
  margin: auto;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.cursor-pointer {
  cursor: pointer;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.bg-white {
  background-color: white;
}
.no-wrap {
  white-space: nowrap;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-shadow {
  box-shadow: none !important;
}

.text-grey {
  color: $grey;
}

.text-info {
  color: $info-color;
}

.text-accent-color {
  color: $accent-color;
}

.text-grey-important {
  color: $grey !important;
}

.color-medium-grey {
  color: $medium-grey;
}
.color-grey2 {
  color: $grey2;
}

.color-ocean {
  color: $ocean;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

$media-sizes: (
  "": "min-width: 0em",
  "mobile-": "max-width: 767px",
  "tablet-": "min-width: 768px",
  "desktop-": "min-width: 1201px",
);

$sizesValue: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25);

@each $size, $i in $media-sizes {
  @media (#{$i}) {
    // ICON SIZE
    @each $iconSize in $sizesValue {
      .#{$size}icon-size-#{$iconSize} {
        font-size: #{$iconSize * 2}px;
        line-height: #{$iconSize * 2}px;
        height: #{$iconSize * 2}px;
        width: #{$iconSize * 2}px;
      }
    }

    // FONT SIZE
    @each $textsize in $sizesValue {
      .#{$size}text-size-#{$textsize} {
        font-size: #{$textsize * 2}px;
      }
    }

    // WIDTH/height
    @each $widthSize in $sizesValue {
      .#{$size}w-#{$widthSize} {
        width: #{$widthSize * 4}px;
      }
      .#{$size}h-#{$widthSize} {
        height: #{$widthSize * 4}px;
      }
    }

    @each $space in $sizesValue {
      .#{$size}m-#{$space} {
        margin: #{$space * 4}px;
      }
      .#{$size}mx-#{$space} {
        margin-left: #{$space * 4}px;
        margin-right: #{$space * 4}px;
      }
      .#{$size}my-#{$space} {
        margin-top: #{$space * 4}px;
        margin-bottom: #{$space * 4}px;
      }
      .#{$size}ml-#{$space} {
        margin-left: #{$space * 4}px;
      }
      .#{$size}mr-#{$space} {
        margin-right: #{$space * 4}px;
      }
      .#{$size}mb-#{$space} {
        margin-bottom: #{$space * 4}px;
      }
      .#{$size}mt-#{$space} {
        margin-top: #{$space * 4}px;
      }
      .#{$size}p-#{$space} {
        padding: #{$space * 4}px;
      }
      .#{$size}px-#{$space} {
        padding-left: #{$space * 4}px;
        padding-right: #{$space * 4}px;
      }
      .#{$size}py-#{$space} {
        padding-top: #{$space * 4}px;
        padding-bottom: #{$space * 4}px;
      }
      .#{$size}pl-#{$space} {
        padding-left: #{$space * 4}px;
      }
      .#{$size}pr-#{$space} {
        padding-right: #{$space * 4}px;
      }
      .#{$size}pb-#{$space} {
        padding-bottom: #{$space * 4}px;
      }
      .#{$size}pt-#{$space} {
        padding-top: #{$space * 4}px;
      }
    }

    // Flex Display
    $flex-display-properties: (
      "-flex": flex,
      "-block": block,
      "-grid": grid,
      "-inline": inline-flex,
      "-none": none,
    );
    @each $label, $property in $flex-display-properties {
      .#{$size + "d" + $label} {
        display: -webkit- + $property;
        display: -moz- + $property;
        display: -ms- + $property + box; // IE10 uses -ms-flexbox
        display: -ms- + $property; // IE11
        display: $property;
      }
    }

    // Flex Direction
    $flex-direction-properties: (
      "-row": row,
      "row-reverse": row-reverse,
      "-column": column,
      "-column-reverse": column-reverse,
    );
    @each $label, $property in $flex-direction-properties {
      .#{$size + "direction" + $label} {
        -webkit-flex-direction: #{$property};
        -moz-flex-direction: #{$property};
        -ms-flex-direction: #{$property};
        flex-direction: #{$property};
      }
    }

    // Flex Wrap
    $flex-wrap-properties: (
      "-wrap": wrap,
      "-nowrap": nowrap,
      "-reverse": wrap-reverse,
    );
    @each $label, $property in $flex-wrap-properties {
      .#{$size + "wrap" + $label} {
        -webkit-flex-wrap: #{$property};
        -moz-flex-wrap: #{$property};
        -ms-flex-wrap: #{$property};
        flex-wrap: #{$property};
      }
    }

    // Justify Content
    $justify-content-properties: (
      "-flex-start": flex-start,
      "-flex-end": flex-end,
      "-center": center,
      "-space-between": space-between,
      "-space-around": space-around,
    );
    @each $label, $property in $justify-content-properties {
      .#{$size + "justify" + $label} {
        -webkit-justify-content: #{$property};
        -moz-justify-content: #{$property};
        -ms-justify-content: #{$property};
        justify-content: #{$property};
      }
    }

    // Justify self
    $justify-content-properties: (
      "-flex-start": flex-start,
      "-flex-end": flex-end,
      "-center": center,
      "-space-between": space-between,
      "-space-around": space-around,
    );
    @each $label, $property in $justify-content-properties {
      .#{$size + "justify-self" + $label} {
        -webkit-justify-self: #{$property};
        -moz-justify-self: #{$property};
        -ms-justify-self: #{$property};
        justify-self: #{$property};
      }
    }

    // Align Content
    $align-content-properties: (
      "-flex-start": flex-start,
      "-flex-end": flex-end,
      "-center": center,
      "-space-between": space-between,
      "-space-around": space-around,
      "-stretch": stretch,
    );
    @each $label, $property in $align-content-properties {
      .#{$size + "align-content" + $label} {
        -webkit-align-content: #{$property};
        -moz-align-content: #{$property};
        -ms-align-content: #{$property};
        align-content: #{$property};
      }
    }

    // Align Items
    $align-items-properties: (
      "-flex-start": flex-start,
      "-flex-end": flex-end,
      "-center": center,
      "-baseline": baseline,
      "-stretch": stretch,
    );
    @each $label, $property in $align-items-properties {
      .#{$size + "align-items" + $label} {
        -webkit-align-items: #{$property};
        -moz-align-items: #{$property};
        -ms-align-items: #{$property};
        align-items: #{$property};
      }
    }

    // Flex Order
    $flex-order-properties: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
    @each $property in $flex-order-properties {
      .#{$size + "order-" + $property} {
        -webkit-order: #{$property};
        -moz-order: #{$property};
        -ms-order: #{$property};
        order: #{$property};
      }
    }

    // Flex Grow
    $flex-grow-properties: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
    @each $property in $flex-grow-properties {
      .#{$size + "grow-" + $property} {
        -webkit-flex-grow: #{$property};
        -moz-flex-grow: #{$property};
        -ms-flex-grow: #{$property};
        flex-grow: #{$property};
      }
    }

    // Flex Shrink
    $flex-shrink-properties: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
    @each $property in $flex-grow-properties {
      .#{$size + "shrink-" + $property} {
        -webkit-flex-shrink: #{$property};
        -moz-flex-shrink: #{$property};
        -ms-flex-shrink: #{$property};
        flex-shrink: #{$property};
      }
    }

    // Align self
    $align-self-properties: (
      "-flex-start": flex-start,
      "-flex-end": flex-end,
      "-center": center,
      "-baseline": baseline,
      "-stretch": stretch,
    );
    @each $label, $property in $align-self-properties {
      .#{$size + "align-self" + $label} {
        -webkit-align-self: #{$property};
        -moz-align-self: #{$property};
        -ms-align-self: #{$property};
        align-self: #{$property};
      }
    }
  }
}
