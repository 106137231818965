:root {
  --primary-color: #E0846B;
  --not-active: rgba(255, 255, 255, 0.3);
  --font-color: rgba(0, 0, 0, 0.87);
  --accent-color: #00A3FF;
  --error-color: #f44336;
  --disabled-color: #a3a3a3;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.underline {
  border-bottom: 1px solid #8b8b8b;
}

.title {
  font-size: 30px;
}

.page-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  font-weight: bold;
}
.subtitle_underline {
  border-bottom: 1px solid black;
}
.subtitle_padding {
  padding-bottom: 15px;
}

.small-title {
  font-size: 14px;
  font-weight: bold;
}

h2 {
  font-weight: 500;
}

.word-break {
  word-break: break-word;
}

.container {
  display: flex;
  padding: 40px;
}
.container_column {
  flex-direction: column;
}
.container_center {
  justify-content: center;
}

.list-container {
  margin: 25px 20px;
  position: sticky;
  top: 25px;
}

.default-tabs {
  background-color: #e2e2e2;
  border: none;
  padding: 0 14px;
}
.default-tabs mat-ink-bar {
  background-color: #ffffff !important;
}
.default-tabs .mat-tab-label-active {
  background-color: #cecece;
}
.default-tabs .mat-tab-link {
  color: black;
  min-width: fit-content;
  text-transform: uppercase;
}

.page-container {
  max-width: 1152px;
  padding: 0 24px;
  padding-top: 32px;
  margin: 0px auto;
}

.border {
  border: 1px solid #e2e2e2;
}

.background-opacity-white {
  background: rgba(255, 255, 255, 0.7);
}

.table {
  width: 100%;
  margin-top: 30px;
}
.table tr {
  text-align: center;
}
.table tr:nth-child(even) {
  background-color: #dedede;
}
.table th {
  background-color: #dedede;
}
.table td,
.table th {
  padding: 10px;
}

.small-field {
  width: 100%;
}
.small-field .mat-error {
  font-size: 11px;
}
.small-field .date-range-wrapper .mat-date-range-input-container {
  margin-top: 3px;
}
.small-field .mat-select-arrow-wrapper {
  height: auto;
}
.small-field .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0;
}
.small-field .date-range-wrapper .mat-form-field-empty.mat-form-field-label, .small-field .date-range-wrapper .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  margin-top: -3px !important;
}
.small-field .mat-form-field-appearance-outline .mat-form-field-label {
  margin-top: -0.6em;
}
.small-field .mat-form-field-should-float .mat-form-field-label {
  margin-top: -0.1em;
}
.small-field .mat-form-field-appearance-outline .mat-form-field-flex {
  height: 40px;
}
.small-field .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.6em 0 1em 0;
}
.small-field .mat-form-field-appearance-outline .mat-form-field-prefix, .small-field .mat-form-field-appearance-outline .mat-form-field-suffix {
  font-size: 15px;
}

.textarea .mat-form-field-appearance-outline .mat-form-field-flex {
  height: auto !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background: white;
  border-radius: 0.3rem;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  border-top-width: 4px;
}
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 4px !important;
}

.mat-slide-toggle-bar {
  height: 22px !important;
  border-radius: 10px !important;
}

.mat-slide-toggle-thumb-container {
  top: 1px !important;
}

.no-label .mat-select-arrow-wrapper {
  transform: translateY(0%) !important;
}
.no-label .mat-form-field-infix {
  padding-bottom: 12px !important;
  border-top: 4px solid transparent;
  padding-left: 3px !important;
}

.select-default .mat-form-field-flex, .default-field .mat-form-field-flex {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px !important;
  padding: 0.45em 0.45em 0 0.45em;
}
.select-default .mat-form-field-infix, .default-field .mat-form-field-infix {
  padding: 0.25em 0 0.45em 0;
}
.select-default .mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex, .default-field .mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
  padding: 0.45em 0.45em 0 0.45em;
  border-radius: 4px;
}
.select-default .mat-form-field-underline, .default-field .mat-form-field-underline {
  width: 0px !important;
  display: none;
}
.select-default .mat-slide-toggle-thumb, .default-field .mat-slide-toggle-thumb {
  width: 22px !important;
  height: 22px !important;
}
.select-default .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container, .default-field .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(29px, 0, 0) !important;
}
.select-default .mat-slide-toggle-bar, .default-field .mat-slide-toggle-bar {
  height: 24px !important;
  width: 52px !important;
  border-radius: 39px !important;
}
.select-default .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb, .default-field .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fff !important;
}
.select-default .mat-slide-toggle.mat-checked .mat-slide-toggle-bar, .default-field .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #00a3ff !important;
}
.select-default.outline .mat-form-field-flex, .default-field.outline .mat-form-field-flex {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.select-default .mat-select-arrow-wrapper, .default-field .mat-select-arrow-wrapper {
  color: #00000080;
}
.select-default-small .mat-form-field-flex, .default-field-small .mat-form-field-flex {
  padding: 0px 12px !important;
}
.select-default-small .mat-form-field-infix, .default-field-small .mat-form-field-infix {
  padding: 9px 0 !important;
}

.default-field .mat-form-field-wrapper {
  padding-bottom: 0;
}
.default-field .mat-form-field-flex {
  height: 42px;
  padding-top: 5px !important;
}

mat-form-field.no-error .mat-form-field-wrapper {
  padding-bottom: 0;
}
mat-form-field.no-error .mat-form-field-wrapper {
  margin: 0;
}
mat-form-field.no-error .mat-form-field-outline-start {
  border-right: none;
}
mat-form-field.no-error .mat-form-field-outline-end {
  border-left: none;
}

.like-field {
  padding: 0.75em 0.75em 0 0.75em;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px !important;
  height: 42px;
}

.set-categories-wrap {
  min-width: 390px !important;
  max-width: unset !important;
  max-height: 500px !important;
  overflow: auto !important;
}
.set-categories-wrap .mat-menu-content {
  height: 100%;
}
.set-categories-wrap .mat-menu-content > div {
  height: 100%;
}

.set-categories-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}

:root {
  --primary-color: #E0846B;
  --not-active: rgba(255, 255, 255, 0.3);
  --font-color: rgba(0, 0, 0, 0.87);
  --accent-color: #00A3FF;
  --error-color: #f44336;
  --disabled-color: #a3a3a3;
}

.table {
  border-collapse: collapse;
}
.table td {
  padding: 15px;
}
.table thead td {
  background-color: #54585d;
  color: white;
  font-weight: bold;
  font-size: 13px;
  border: 1px solid #54585d;
}
.table tbody td {
  color: #636363;
  border: 1px solid #dddfe1;
}
.table tbody tr {
  background-color: #f9fafb;
}
.table tbody tr:nth-child(odd) {
  background-color: white;
}

mat-table.table {
  flex: 1;
  width: auto;
  overflow-y: auto;
  background-color: white;
}
mat-table.table .table__header-row {
  border: 0;
}
mat-table.table .table__header-cell {
  font-size: 1rem;
  font-weight: normal;
  color: var(--font-color);
}
mat-table.table .table__cell {
  font-size: 0.95rem;
  color: rgba(0, 0, 0, 0.6);
}
mat-table.table .table__row {
  border: 0;
  transition: background-color 0.23s ease;
}
mat-table.table .table__row--link {
  cursor: pointer;
}
mat-table.table .table__row:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.mat-sort-header.center .mat-sort-header-container {
  justify-content: center;
  padding-left: 12px;
}

mat-table.clear-table {
  overflow-y: auto;
}
mat-table.clear-table::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(0, 0, 0, 0.2);
}
mat-table.clear-table::-webkit-scrollbar-thumb {
  background-color: #e0846b;
}
mat-table.clear-table mat-row,
mat-table.clear-table mat-header-row,
mat-table.clear-table mat-footer-row {
  border-bottom-width: 0px !important;
}
mat-table.clear-table mat-header-cell {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}
mat-table.clear-table mat-header-cell:last-child {
  padding-right: 0;
}
mat-table.clear-table mat-cell:last-child {
  padding-right: 0;
  flex-direction: reverse;
  justify-content: center;
}

.circle-status {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}
.circle-status-green {
  background-color: #8be37c;
}
.circle-status-red {
  background: #F7AEA4;
}

.default {
  height: 48px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px !important;
}

.medium {
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  min-width: 90px !important;
  border-radius: 4px !important;
}

.link {
  font-family: "Roboto";
  font-weight: 500;
  color: #00A3FF;
}

button.mat-ocean {
  background: #2C4657;
  color: white;
}

button.mat-accent-green {
  background: #34CA1C;
  color: white;
}

:root {
  --primary-color: #E0846B;
  --not-active: rgba(255, 255, 255, 0.3);
  --font-color: rgba(0, 0, 0, 0.87);
  --accent-color: #00A3FF;
  --error-color: #f44336;
  --disabled-color: #a3a3a3;
}

.w-full {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-33 {
  width: 33%;
}

.w-1\4  {
  width: 25%;
}

.h-full {
  height: 100%;
}

.h-full-without-header-footer {
  height: calc(100% - 48px - 80px);
}

.min-h-full {
  min-height: 100%;
}

.min-w-unset {
  min-width: unset !important;
}

.m-auto {
  margin: auto;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-white {
  background-color: white;
}

.no-wrap {
  white-space: nowrap;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-shadow {
  box-shadow: none !important;
}

.text-grey {
  color: #999;
}

.text-info {
  color: #FF7B52;
}

.text-accent-color {
  color: #00A3FF;
}

.text-grey-important {
  color: #999 !important;
}

.color-medium-grey {
  color: #8C8C8C;
}

.color-grey2 {
  color: #6a7d88;
}

.color-ocean {
  color: #2C4657;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

@media (min-width: 0em) {
  .icon-size-0 {
    font-size: 0px;
    line-height: 0px;
    height: 0px;
    width: 0px;
  }

  .icon-size-1 {
    font-size: 2px;
    line-height: 2px;
    height: 2px;
    width: 2px;
  }

  .icon-size-2 {
    font-size: 4px;
    line-height: 4px;
    height: 4px;
    width: 4px;
  }

  .icon-size-3 {
    font-size: 6px;
    line-height: 6px;
    height: 6px;
    width: 6px;
  }

  .icon-size-4 {
    font-size: 8px;
    line-height: 8px;
    height: 8px;
    width: 8px;
  }

  .icon-size-5 {
    font-size: 10px;
    line-height: 10px;
    height: 10px;
    width: 10px;
  }

  .icon-size-6 {
    font-size: 12px;
    line-height: 12px;
    height: 12px;
    width: 12px;
  }

  .icon-size-7 {
    font-size: 14px;
    line-height: 14px;
    height: 14px;
    width: 14px;
  }

  .icon-size-8 {
    font-size: 16px;
    line-height: 16px;
    height: 16px;
    width: 16px;
  }

  .icon-size-9 {
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: 18px;
  }

  .icon-size-10 {
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    width: 20px;
  }

  .icon-size-11 {
    font-size: 22px;
    line-height: 22px;
    height: 22px;
    width: 22px;
  }

  .icon-size-12 {
    font-size: 24px;
    line-height: 24px;
    height: 24px;
    width: 24px;
  }

  .icon-size-13 {
    font-size: 26px;
    line-height: 26px;
    height: 26px;
    width: 26px;
  }

  .icon-size-14 {
    font-size: 28px;
    line-height: 28px;
    height: 28px;
    width: 28px;
  }

  .icon-size-15 {
    font-size: 30px;
    line-height: 30px;
    height: 30px;
    width: 30px;
  }

  .icon-size-16 {
    font-size: 32px;
    line-height: 32px;
    height: 32px;
    width: 32px;
  }

  .icon-size-17 {
    font-size: 34px;
    line-height: 34px;
    height: 34px;
    width: 34px;
  }

  .icon-size-18 {
    font-size: 36px;
    line-height: 36px;
    height: 36px;
    width: 36px;
  }

  .icon-size-19 {
    font-size: 38px;
    line-height: 38px;
    height: 38px;
    width: 38px;
  }

  .icon-size-20 {
    font-size: 40px;
    line-height: 40px;
    height: 40px;
    width: 40px;
  }

  .icon-size-25 {
    font-size: 50px;
    line-height: 50px;
    height: 50px;
    width: 50px;
  }

  .text-size-0 {
    font-size: 0px;
  }

  .text-size-1 {
    font-size: 2px;
  }

  .text-size-2 {
    font-size: 4px;
  }

  .text-size-3 {
    font-size: 6px;
  }

  .text-size-4 {
    font-size: 8px;
  }

  .text-size-5 {
    font-size: 10px;
  }

  .text-size-6 {
    font-size: 12px;
  }

  .text-size-7 {
    font-size: 14px;
  }

  .text-size-8 {
    font-size: 16px;
  }

  .text-size-9 {
    font-size: 18px;
  }

  .text-size-10 {
    font-size: 20px;
  }

  .text-size-11 {
    font-size: 22px;
  }

  .text-size-12 {
    font-size: 24px;
  }

  .text-size-13 {
    font-size: 26px;
  }

  .text-size-14 {
    font-size: 28px;
  }

  .text-size-15 {
    font-size: 30px;
  }

  .text-size-16 {
    font-size: 32px;
  }

  .text-size-17 {
    font-size: 34px;
  }

  .text-size-18 {
    font-size: 36px;
  }

  .text-size-19 {
    font-size: 38px;
  }

  .text-size-20 {
    font-size: 40px;
  }

  .text-size-25 {
    font-size: 50px;
  }

  .w-0 {
    width: 0px;
  }

  .h-0 {
    height: 0px;
  }

  .w-1 {
    width: 4px;
  }

  .h-1 {
    height: 4px;
  }

  .w-2 {
    width: 8px;
  }

  .h-2 {
    height: 8px;
  }

  .w-3 {
    width: 12px;
  }

  .h-3 {
    height: 12px;
  }

  .w-4 {
    width: 16px;
  }

  .h-4 {
    height: 16px;
  }

  .w-5 {
    width: 20px;
  }

  .h-5 {
    height: 20px;
  }

  .w-6 {
    width: 24px;
  }

  .h-6 {
    height: 24px;
  }

  .w-7 {
    width: 28px;
  }

  .h-7 {
    height: 28px;
  }

  .w-8 {
    width: 32px;
  }

  .h-8 {
    height: 32px;
  }

  .w-9 {
    width: 36px;
  }

  .h-9 {
    height: 36px;
  }

  .w-10 {
    width: 40px;
  }

  .h-10 {
    height: 40px;
  }

  .w-11 {
    width: 44px;
  }

  .h-11 {
    height: 44px;
  }

  .w-12 {
    width: 48px;
  }

  .h-12 {
    height: 48px;
  }

  .w-13 {
    width: 52px;
  }

  .h-13 {
    height: 52px;
  }

  .w-14 {
    width: 56px;
  }

  .h-14 {
    height: 56px;
  }

  .w-15 {
    width: 60px;
  }

  .h-15 {
    height: 60px;
  }

  .w-16 {
    width: 64px;
  }

  .h-16 {
    height: 64px;
  }

  .w-17 {
    width: 68px;
  }

  .h-17 {
    height: 68px;
  }

  .w-18 {
    width: 72px;
  }

  .h-18 {
    height: 72px;
  }

  .w-19 {
    width: 76px;
  }

  .h-19 {
    height: 76px;
  }

  .w-20 {
    width: 80px;
  }

  .h-20 {
    height: 80px;
  }

  .w-25 {
    width: 100px;
  }

  .h-25 {
    height: 100px;
  }

  .m-0 {
    margin: 0px;
  }

  .mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .ml-0 {
    margin-left: 0px;
  }

  .mr-0 {
    margin-right: 0px;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .mt-0 {
    margin-top: 0px;
  }

  .p-0 {
    padding: 0px;
  }

  .px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .pl-0 {
    padding-left: 0px;
  }

  .pr-0 {
    padding-right: 0px;
  }

  .pb-0 {
    padding-bottom: 0px;
  }

  .pt-0 {
    padding-top: 0px;
  }

  .m-1 {
    margin: 4px;
  }

  .mx-1 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .ml-1 {
    margin-left: 4px;
  }

  .mr-1 {
    margin-right: 4px;
  }

  .mb-1 {
    margin-bottom: 4px;
  }

  .mt-1 {
    margin-top: 4px;
  }

  .p-1 {
    padding: 4px;
  }

  .px-1 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pl-1 {
    padding-left: 4px;
  }

  .pr-1 {
    padding-right: 4px;
  }

  .pb-1 {
    padding-bottom: 4px;
  }

  .pt-1 {
    padding-top: 4px;
  }

  .m-2 {
    margin: 8px;
  }

  .mx-2 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .ml-2 {
    margin-left: 8px;
  }

  .mr-2 {
    margin-right: 8px;
  }

  .mb-2 {
    margin-bottom: 8px;
  }

  .mt-2 {
    margin-top: 8px;
  }

  .p-2 {
    padding: 8px;
  }

  .px-2 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .pl-2 {
    padding-left: 8px;
  }

  .pr-2 {
    padding-right: 8px;
  }

  .pb-2 {
    padding-bottom: 8px;
  }

  .pt-2 {
    padding-top: 8px;
  }

  .m-3 {
    margin: 12px;
  }

  .mx-3 {
    margin-left: 12px;
    margin-right: 12px;
  }

  .my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .ml-3 {
    margin-left: 12px;
  }

  .mr-3 {
    margin-right: 12px;
  }

  .mb-3 {
    margin-bottom: 12px;
  }

  .mt-3 {
    margin-top: 12px;
  }

  .p-3 {
    padding: 12px;
  }

  .px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }

  .py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .pl-3 {
    padding-left: 12px;
  }

  .pr-3 {
    padding-right: 12px;
  }

  .pb-3 {
    padding-bottom: 12px;
  }

  .pt-3 {
    padding-top: 12px;
  }

  .m-4 {
    margin: 16px;
  }

  .mx-4 {
    margin-left: 16px;
    margin-right: 16px;
  }

  .my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .ml-4 {
    margin-left: 16px;
  }

  .mr-4 {
    margin-right: 16px;
  }

  .mb-4 {
    margin-bottom: 16px;
  }

  .mt-4 {
    margin-top: 16px;
  }

  .p-4 {
    padding: 16px;
  }

  .px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .py-4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .pl-4 {
    padding-left: 16px;
  }

  .pr-4 {
    padding-right: 16px;
  }

  .pb-4 {
    padding-bottom: 16px;
  }

  .pt-4 {
    padding-top: 16px;
  }

  .m-5 {
    margin: 20px;
  }

  .mx-5 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .my-5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .ml-5 {
    margin-left: 20px;
  }

  .mr-5 {
    margin-right: 20px;
  }

  .mb-5 {
    margin-bottom: 20px;
  }

  .mt-5 {
    margin-top: 20px;
  }

  .p-5 {
    padding: 20px;
  }

  .px-5 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pl-5 {
    padding-left: 20px;
  }

  .pr-5 {
    padding-right: 20px;
  }

  .pb-5 {
    padding-bottom: 20px;
  }

  .pt-5 {
    padding-top: 20px;
  }

  .m-6 {
    margin: 24px;
  }

  .mx-6 {
    margin-left: 24px;
    margin-right: 24px;
  }

  .my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .ml-6 {
    margin-left: 24px;
  }

  .mr-6 {
    margin-right: 24px;
  }

  .mb-6 {
    margin-bottom: 24px;
  }

  .mt-6 {
    margin-top: 24px;
  }

  .p-6 {
    padding: 24px;
  }

  .px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .pl-6 {
    padding-left: 24px;
  }

  .pr-6 {
    padding-right: 24px;
  }

  .pb-6 {
    padding-bottom: 24px;
  }

  .pt-6 {
    padding-top: 24px;
  }

  .m-7 {
    margin: 28px;
  }

  .mx-7 {
    margin-left: 28px;
    margin-right: 28px;
  }

  .my-7 {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .ml-7 {
    margin-left: 28px;
  }

  .mr-7 {
    margin-right: 28px;
  }

  .mb-7 {
    margin-bottom: 28px;
  }

  .mt-7 {
    margin-top: 28px;
  }

  .p-7 {
    padding: 28px;
  }

  .px-7 {
    padding-left: 28px;
    padding-right: 28px;
  }

  .py-7 {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .pl-7 {
    padding-left: 28px;
  }

  .pr-7 {
    padding-right: 28px;
  }

  .pb-7 {
    padding-bottom: 28px;
  }

  .pt-7 {
    padding-top: 28px;
  }

  .m-8 {
    margin: 32px;
  }

  .mx-8 {
    margin-left: 32px;
    margin-right: 32px;
  }

  .my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .ml-8 {
    margin-left: 32px;
  }

  .mr-8 {
    margin-right: 32px;
  }

  .mb-8 {
    margin-bottom: 32px;
  }

  .mt-8 {
    margin-top: 32px;
  }

  .p-8 {
    padding: 32px;
  }

  .px-8 {
    padding-left: 32px;
    padding-right: 32px;
  }

  .py-8 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .pl-8 {
    padding-left: 32px;
  }

  .pr-8 {
    padding-right: 32px;
  }

  .pb-8 {
    padding-bottom: 32px;
  }

  .pt-8 {
    padding-top: 32px;
  }

  .m-9 {
    margin: 36px;
  }

  .mx-9 {
    margin-left: 36px;
    margin-right: 36px;
  }

  .my-9 {
    margin-top: 36px;
    margin-bottom: 36px;
  }

  .ml-9 {
    margin-left: 36px;
  }

  .mr-9 {
    margin-right: 36px;
  }

  .mb-9 {
    margin-bottom: 36px;
  }

  .mt-9 {
    margin-top: 36px;
  }

  .p-9 {
    padding: 36px;
  }

  .px-9 {
    padding-left: 36px;
    padding-right: 36px;
  }

  .py-9 {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .pl-9 {
    padding-left: 36px;
  }

  .pr-9 {
    padding-right: 36px;
  }

  .pb-9 {
    padding-bottom: 36px;
  }

  .pt-9 {
    padding-top: 36px;
  }

  .m-10 {
    margin: 40px;
  }

  .mx-10 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .my-10 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .ml-10 {
    margin-left: 40px;
  }

  .mr-10 {
    margin-right: 40px;
  }

  .mb-10 {
    margin-bottom: 40px;
  }

  .mt-10 {
    margin-top: 40px;
  }

  .p-10 {
    padding: 40px;
  }

  .px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .py-10 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pl-10 {
    padding-left: 40px;
  }

  .pr-10 {
    padding-right: 40px;
  }

  .pb-10 {
    padding-bottom: 40px;
  }

  .pt-10 {
    padding-top: 40px;
  }

  .m-11 {
    margin: 44px;
  }

  .mx-11 {
    margin-left: 44px;
    margin-right: 44px;
  }

  .my-11 {
    margin-top: 44px;
    margin-bottom: 44px;
  }

  .ml-11 {
    margin-left: 44px;
  }

  .mr-11 {
    margin-right: 44px;
  }

  .mb-11 {
    margin-bottom: 44px;
  }

  .mt-11 {
    margin-top: 44px;
  }

  .p-11 {
    padding: 44px;
  }

  .px-11 {
    padding-left: 44px;
    padding-right: 44px;
  }

  .py-11 {
    padding-top: 44px;
    padding-bottom: 44px;
  }

  .pl-11 {
    padding-left: 44px;
  }

  .pr-11 {
    padding-right: 44px;
  }

  .pb-11 {
    padding-bottom: 44px;
  }

  .pt-11 {
    padding-top: 44px;
  }

  .m-12 {
    margin: 48px;
  }

  .mx-12 {
    margin-left: 48px;
    margin-right: 48px;
  }

  .my-12 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .ml-12 {
    margin-left: 48px;
  }

  .mr-12 {
    margin-right: 48px;
  }

  .mb-12 {
    margin-bottom: 48px;
  }

  .mt-12 {
    margin-top: 48px;
  }

  .p-12 {
    padding: 48px;
  }

  .px-12 {
    padding-left: 48px;
    padding-right: 48px;
  }

  .py-12 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .pl-12 {
    padding-left: 48px;
  }

  .pr-12 {
    padding-right: 48px;
  }

  .pb-12 {
    padding-bottom: 48px;
  }

  .pt-12 {
    padding-top: 48px;
  }

  .m-13 {
    margin: 52px;
  }

  .mx-13 {
    margin-left: 52px;
    margin-right: 52px;
  }

  .my-13 {
    margin-top: 52px;
    margin-bottom: 52px;
  }

  .ml-13 {
    margin-left: 52px;
  }

  .mr-13 {
    margin-right: 52px;
  }

  .mb-13 {
    margin-bottom: 52px;
  }

  .mt-13 {
    margin-top: 52px;
  }

  .p-13 {
    padding: 52px;
  }

  .px-13 {
    padding-left: 52px;
    padding-right: 52px;
  }

  .py-13 {
    padding-top: 52px;
    padding-bottom: 52px;
  }

  .pl-13 {
    padding-left: 52px;
  }

  .pr-13 {
    padding-right: 52px;
  }

  .pb-13 {
    padding-bottom: 52px;
  }

  .pt-13 {
    padding-top: 52px;
  }

  .m-14 {
    margin: 56px;
  }

  .mx-14 {
    margin-left: 56px;
    margin-right: 56px;
  }

  .my-14 {
    margin-top: 56px;
    margin-bottom: 56px;
  }

  .ml-14 {
    margin-left: 56px;
  }

  .mr-14 {
    margin-right: 56px;
  }

  .mb-14 {
    margin-bottom: 56px;
  }

  .mt-14 {
    margin-top: 56px;
  }

  .p-14 {
    padding: 56px;
  }

  .px-14 {
    padding-left: 56px;
    padding-right: 56px;
  }

  .py-14 {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .pl-14 {
    padding-left: 56px;
  }

  .pr-14 {
    padding-right: 56px;
  }

  .pb-14 {
    padding-bottom: 56px;
  }

  .pt-14 {
    padding-top: 56px;
  }

  .m-15 {
    margin: 60px;
  }

  .mx-15 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .my-15 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .ml-15 {
    margin-left: 60px;
  }

  .mr-15 {
    margin-right: 60px;
  }

  .mb-15 {
    margin-bottom: 60px;
  }

  .mt-15 {
    margin-top: 60px;
  }

  .p-15 {
    padding: 60px;
  }

  .px-15 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .py-15 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pl-15 {
    padding-left: 60px;
  }

  .pr-15 {
    padding-right: 60px;
  }

  .pb-15 {
    padding-bottom: 60px;
  }

  .pt-15 {
    padding-top: 60px;
  }

  .m-16 {
    margin: 64px;
  }

  .mx-16 {
    margin-left: 64px;
    margin-right: 64px;
  }

  .my-16 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .ml-16 {
    margin-left: 64px;
  }

  .mr-16 {
    margin-right: 64px;
  }

  .mb-16 {
    margin-bottom: 64px;
  }

  .mt-16 {
    margin-top: 64px;
  }

  .p-16 {
    padding: 64px;
  }

  .px-16 {
    padding-left: 64px;
    padding-right: 64px;
  }

  .py-16 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .pl-16 {
    padding-left: 64px;
  }

  .pr-16 {
    padding-right: 64px;
  }

  .pb-16 {
    padding-bottom: 64px;
  }

  .pt-16 {
    padding-top: 64px;
  }

  .m-17 {
    margin: 68px;
  }

  .mx-17 {
    margin-left: 68px;
    margin-right: 68px;
  }

  .my-17 {
    margin-top: 68px;
    margin-bottom: 68px;
  }

  .ml-17 {
    margin-left: 68px;
  }

  .mr-17 {
    margin-right: 68px;
  }

  .mb-17 {
    margin-bottom: 68px;
  }

  .mt-17 {
    margin-top: 68px;
  }

  .p-17 {
    padding: 68px;
  }

  .px-17 {
    padding-left: 68px;
    padding-right: 68px;
  }

  .py-17 {
    padding-top: 68px;
    padding-bottom: 68px;
  }

  .pl-17 {
    padding-left: 68px;
  }

  .pr-17 {
    padding-right: 68px;
  }

  .pb-17 {
    padding-bottom: 68px;
  }

  .pt-17 {
    padding-top: 68px;
  }

  .m-18 {
    margin: 72px;
  }

  .mx-18 {
    margin-left: 72px;
    margin-right: 72px;
  }

  .my-18 {
    margin-top: 72px;
    margin-bottom: 72px;
  }

  .ml-18 {
    margin-left: 72px;
  }

  .mr-18 {
    margin-right: 72px;
  }

  .mb-18 {
    margin-bottom: 72px;
  }

  .mt-18 {
    margin-top: 72px;
  }

  .p-18 {
    padding: 72px;
  }

  .px-18 {
    padding-left: 72px;
    padding-right: 72px;
  }

  .py-18 {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .pl-18 {
    padding-left: 72px;
  }

  .pr-18 {
    padding-right: 72px;
  }

  .pb-18 {
    padding-bottom: 72px;
  }

  .pt-18 {
    padding-top: 72px;
  }

  .m-19 {
    margin: 76px;
  }

  .mx-19 {
    margin-left: 76px;
    margin-right: 76px;
  }

  .my-19 {
    margin-top: 76px;
    margin-bottom: 76px;
  }

  .ml-19 {
    margin-left: 76px;
  }

  .mr-19 {
    margin-right: 76px;
  }

  .mb-19 {
    margin-bottom: 76px;
  }

  .mt-19 {
    margin-top: 76px;
  }

  .p-19 {
    padding: 76px;
  }

  .px-19 {
    padding-left: 76px;
    padding-right: 76px;
  }

  .py-19 {
    padding-top: 76px;
    padding-bottom: 76px;
  }

  .pl-19 {
    padding-left: 76px;
  }

  .pr-19 {
    padding-right: 76px;
  }

  .pb-19 {
    padding-bottom: 76px;
  }

  .pt-19 {
    padding-top: 76px;
  }

  .m-20 {
    margin: 80px;
  }

  .mx-20 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .my-20 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .ml-20 {
    margin-left: 80px;
  }

  .mr-20 {
    margin-right: 80px;
  }

  .mb-20 {
    margin-bottom: 80px;
  }

  .mt-20 {
    margin-top: 80px;
  }

  .p-20 {
    padding: 80px;
  }

  .px-20 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .py-20 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pl-20 {
    padding-left: 80px;
  }

  .pr-20 {
    padding-right: 80px;
  }

  .pb-20 {
    padding-bottom: 80px;
  }

  .pt-20 {
    padding-top: 80px;
  }

  .m-25 {
    margin: 100px;
  }

  .mx-25 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .my-25 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .ml-25 {
    margin-left: 100px;
  }

  .mr-25 {
    margin-right: 100px;
  }

  .mb-25 {
    margin-bottom: 100px;
  }

  .mt-25 {
    margin-top: 100px;
  }

  .p-25 {
    padding: 100px;
  }

  .px-25 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .py-25 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pl-25 {
    padding-left: 100px;
  }

  .pr-25 {
    padding-right: 100px;
  }

  .pb-25 {
    padding-bottom: 100px;
  }

  .pt-25 {
    padding-top: 100px;
  }

  .d-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
  }

  .d-block {
    display: -webkit-block;
    display: -moz-block;
    display: -ms-blockbox;
    display: -ms-block;
    display: block;
  }

  .d-grid {
    display: -webkit-grid;
    display: -moz-grid;
    display: -ms-gridbox;
    display: -ms-grid;
    display: grid;
  }

  .d-inline {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: -ms-inline-flex;
    display: inline-flex;
  }

  .d-none {
    display: -webkit-none;
    display: -moz-none;
    display: -ms-nonebox;
    display: -ms-none;
    display: none;
  }

  .direction-row {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .directionrow-reverse {
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .direction-column {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .direction-column-reverse {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .wrap-wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .wrap-nowrap {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -moz-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }

  .justify-flex-start {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }

  .justify-flex-end {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
  }

  .justify-center {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }

  .justify-space-between {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
  }

  .justify-space-around {
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
  }

  .justify-self-flex-start {
    -webkit-justify-self: flex-start;
    -moz-justify-self: flex-start;
    -ms-justify-self: flex-start;
    justify-self: flex-start;
  }

  .justify-self-flex-end {
    -webkit-justify-self: flex-end;
    -moz-justify-self: flex-end;
    -ms-justify-self: flex-end;
    justify-self: flex-end;
  }

  .justify-self-center {
    -webkit-justify-self: center;
    -moz-justify-self: center;
    -ms-justify-self: center;
    justify-self: center;
  }

  .justify-self-space-between {
    -webkit-justify-self: space-between;
    -moz-justify-self: space-between;
    -ms-justify-self: space-between;
    justify-self: space-between;
  }

  .justify-self-space-around {
    -webkit-justify-self: space-around;
    -moz-justify-self: space-around;
    -ms-justify-self: space-around;
    justify-self: space-around;
  }

  .align-content-flex-start {
    -webkit-align-content: flex-start;
    -moz-align-content: flex-start;
    -ms-align-content: flex-start;
    align-content: flex-start;
  }

  .align-content-flex-end {
    -webkit-align-content: flex-end;
    -moz-align-content: flex-end;
    -ms-align-content: flex-end;
    align-content: flex-end;
  }

  .align-content-center {
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
  }

  .align-content-space-between {
    -webkit-align-content: space-between;
    -moz-align-content: space-between;
    -ms-align-content: space-between;
    align-content: space-between;
  }

  .align-content-space-around {
    -webkit-align-content: space-around;
    -moz-align-content: space-around;
    -ms-align-content: space-around;
    align-content: space-around;
  }

  .align-content-stretch {
    -webkit-align-content: stretch;
    -moz-align-content: stretch;
    -ms-align-content: stretch;
    align-content: stretch;
  }

  .align-items-flex-start {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }

  .align-items-flex-end {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
  }

  .align-items-center {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }

  .align-items-baseline {
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
  }

  .align-items-stretch {
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
  }

  .order-0 {
    -webkit-order: 0;
    -moz-order: 0;
    -ms-order: 0;
    order: 0;
  }

  .order-1 {
    -webkit-order: 1;
    -moz-order: 1;
    -ms-order: 1;
    order: 1;
  }

  .order-2 {
    -webkit-order: 2;
    -moz-order: 2;
    -ms-order: 2;
    order: 2;
  }

  .order-3 {
    -webkit-order: 3;
    -moz-order: 3;
    -ms-order: 3;
    order: 3;
  }

  .order-4 {
    -webkit-order: 4;
    -moz-order: 4;
    -ms-order: 4;
    order: 4;
  }

  .order-5 {
    -webkit-order: 5;
    -moz-order: 5;
    -ms-order: 5;
    order: 5;
  }

  .order-6 {
    -webkit-order: 6;
    -moz-order: 6;
    -ms-order: 6;
    order: 6;
  }

  .order-7 {
    -webkit-order: 7;
    -moz-order: 7;
    -ms-order: 7;
    order: 7;
  }

  .order-8 {
    -webkit-order: 8;
    -moz-order: 8;
    -ms-order: 8;
    order: 8;
  }

  .order-9 {
    -webkit-order: 9;
    -moz-order: 9;
    -ms-order: 9;
    order: 9;
  }

  .order-10 {
    -webkit-order: 10;
    -moz-order: 10;
    -ms-order: 10;
    order: 10;
  }

  .grow-0 {
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-grow: 0;
    flex-grow: 0;
  }

  .grow-1 {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
  }

  .grow-2 {
    -webkit-flex-grow: 2;
    -moz-flex-grow: 2;
    -ms-flex-grow: 2;
    flex-grow: 2;
  }

  .grow-3 {
    -webkit-flex-grow: 3;
    -moz-flex-grow: 3;
    -ms-flex-grow: 3;
    flex-grow: 3;
  }

  .grow-4 {
    -webkit-flex-grow: 4;
    -moz-flex-grow: 4;
    -ms-flex-grow: 4;
    flex-grow: 4;
  }

  .grow-5 {
    -webkit-flex-grow: 5;
    -moz-flex-grow: 5;
    -ms-flex-grow: 5;
    flex-grow: 5;
  }

  .grow-6 {
    -webkit-flex-grow: 6;
    -moz-flex-grow: 6;
    -ms-flex-grow: 6;
    flex-grow: 6;
  }

  .grow-7 {
    -webkit-flex-grow: 7;
    -moz-flex-grow: 7;
    -ms-flex-grow: 7;
    flex-grow: 7;
  }

  .grow-8 {
    -webkit-flex-grow: 8;
    -moz-flex-grow: 8;
    -ms-flex-grow: 8;
    flex-grow: 8;
  }

  .grow-9 {
    -webkit-flex-grow: 9;
    -moz-flex-grow: 9;
    -ms-flex-grow: 9;
    flex-grow: 9;
  }

  .grow-10 {
    -webkit-flex-grow: 10;
    -moz-flex-grow: 10;
    -ms-flex-grow: 10;
    flex-grow: 10;
  }

  .shrink-0 {
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
  }

  .shrink-1 {
    -webkit-flex-shrink: 1;
    -moz-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
  }

  .shrink-2 {
    -webkit-flex-shrink: 2;
    -moz-flex-shrink: 2;
    -ms-flex-shrink: 2;
    flex-shrink: 2;
  }

  .shrink-3 {
    -webkit-flex-shrink: 3;
    -moz-flex-shrink: 3;
    -ms-flex-shrink: 3;
    flex-shrink: 3;
  }

  .shrink-4 {
    -webkit-flex-shrink: 4;
    -moz-flex-shrink: 4;
    -ms-flex-shrink: 4;
    flex-shrink: 4;
  }

  .shrink-5 {
    -webkit-flex-shrink: 5;
    -moz-flex-shrink: 5;
    -ms-flex-shrink: 5;
    flex-shrink: 5;
  }

  .shrink-6 {
    -webkit-flex-shrink: 6;
    -moz-flex-shrink: 6;
    -ms-flex-shrink: 6;
    flex-shrink: 6;
  }

  .shrink-7 {
    -webkit-flex-shrink: 7;
    -moz-flex-shrink: 7;
    -ms-flex-shrink: 7;
    flex-shrink: 7;
  }

  .shrink-8 {
    -webkit-flex-shrink: 8;
    -moz-flex-shrink: 8;
    -ms-flex-shrink: 8;
    flex-shrink: 8;
  }

  .shrink-9 {
    -webkit-flex-shrink: 9;
    -moz-flex-shrink: 9;
    -ms-flex-shrink: 9;
    flex-shrink: 9;
  }

  .shrink-10 {
    -webkit-flex-shrink: 10;
    -moz-flex-shrink: 10;
    -ms-flex-shrink: 10;
    flex-shrink: 10;
  }

  .align-self-flex-start {
    -webkit-align-self: flex-start;
    -moz-align-self: flex-start;
    -ms-align-self: flex-start;
    align-self: flex-start;
  }

  .align-self-flex-end {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-align-self: flex-end;
    align-self: flex-end;
  }

  .align-self-center {
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-align-self: center;
    align-self: center;
  }

  .align-self-baseline {
    -webkit-align-self: baseline;
    -moz-align-self: baseline;
    -ms-align-self: baseline;
    align-self: baseline;
  }

  .align-self-stretch {
    -webkit-align-self: stretch;
    -moz-align-self: stretch;
    -ms-align-self: stretch;
    align-self: stretch;
  }
}
@media (max-width: 767px) {
  .mobile-icon-size-0 {
    font-size: 0px;
    line-height: 0px;
    height: 0px;
    width: 0px;
  }

  .mobile-icon-size-1 {
    font-size: 2px;
    line-height: 2px;
    height: 2px;
    width: 2px;
  }

  .mobile-icon-size-2 {
    font-size: 4px;
    line-height: 4px;
    height: 4px;
    width: 4px;
  }

  .mobile-icon-size-3 {
    font-size: 6px;
    line-height: 6px;
    height: 6px;
    width: 6px;
  }

  .mobile-icon-size-4 {
    font-size: 8px;
    line-height: 8px;
    height: 8px;
    width: 8px;
  }

  .mobile-icon-size-5 {
    font-size: 10px;
    line-height: 10px;
    height: 10px;
    width: 10px;
  }

  .mobile-icon-size-6 {
    font-size: 12px;
    line-height: 12px;
    height: 12px;
    width: 12px;
  }

  .mobile-icon-size-7 {
    font-size: 14px;
    line-height: 14px;
    height: 14px;
    width: 14px;
  }

  .mobile-icon-size-8 {
    font-size: 16px;
    line-height: 16px;
    height: 16px;
    width: 16px;
  }

  .mobile-icon-size-9 {
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: 18px;
  }

  .mobile-icon-size-10 {
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    width: 20px;
  }

  .mobile-icon-size-11 {
    font-size: 22px;
    line-height: 22px;
    height: 22px;
    width: 22px;
  }

  .mobile-icon-size-12 {
    font-size: 24px;
    line-height: 24px;
    height: 24px;
    width: 24px;
  }

  .mobile-icon-size-13 {
    font-size: 26px;
    line-height: 26px;
    height: 26px;
    width: 26px;
  }

  .mobile-icon-size-14 {
    font-size: 28px;
    line-height: 28px;
    height: 28px;
    width: 28px;
  }

  .mobile-icon-size-15 {
    font-size: 30px;
    line-height: 30px;
    height: 30px;
    width: 30px;
  }

  .mobile-icon-size-16 {
    font-size: 32px;
    line-height: 32px;
    height: 32px;
    width: 32px;
  }

  .mobile-icon-size-17 {
    font-size: 34px;
    line-height: 34px;
    height: 34px;
    width: 34px;
  }

  .mobile-icon-size-18 {
    font-size: 36px;
    line-height: 36px;
    height: 36px;
    width: 36px;
  }

  .mobile-icon-size-19 {
    font-size: 38px;
    line-height: 38px;
    height: 38px;
    width: 38px;
  }

  .mobile-icon-size-20 {
    font-size: 40px;
    line-height: 40px;
    height: 40px;
    width: 40px;
  }

  .mobile-icon-size-25 {
    font-size: 50px;
    line-height: 50px;
    height: 50px;
    width: 50px;
  }

  .mobile-text-size-0 {
    font-size: 0px;
  }

  .mobile-text-size-1 {
    font-size: 2px;
  }

  .mobile-text-size-2 {
    font-size: 4px;
  }

  .mobile-text-size-3 {
    font-size: 6px;
  }

  .mobile-text-size-4 {
    font-size: 8px;
  }

  .mobile-text-size-5 {
    font-size: 10px;
  }

  .mobile-text-size-6 {
    font-size: 12px;
  }

  .mobile-text-size-7 {
    font-size: 14px;
  }

  .mobile-text-size-8 {
    font-size: 16px;
  }

  .mobile-text-size-9 {
    font-size: 18px;
  }

  .mobile-text-size-10 {
    font-size: 20px;
  }

  .mobile-text-size-11 {
    font-size: 22px;
  }

  .mobile-text-size-12 {
    font-size: 24px;
  }

  .mobile-text-size-13 {
    font-size: 26px;
  }

  .mobile-text-size-14 {
    font-size: 28px;
  }

  .mobile-text-size-15 {
    font-size: 30px;
  }

  .mobile-text-size-16 {
    font-size: 32px;
  }

  .mobile-text-size-17 {
    font-size: 34px;
  }

  .mobile-text-size-18 {
    font-size: 36px;
  }

  .mobile-text-size-19 {
    font-size: 38px;
  }

  .mobile-text-size-20 {
    font-size: 40px;
  }

  .mobile-text-size-25 {
    font-size: 50px;
  }

  .mobile-w-0 {
    width: 0px;
  }

  .mobile-h-0 {
    height: 0px;
  }

  .mobile-w-1 {
    width: 4px;
  }

  .mobile-h-1 {
    height: 4px;
  }

  .mobile-w-2 {
    width: 8px;
  }

  .mobile-h-2 {
    height: 8px;
  }

  .mobile-w-3 {
    width: 12px;
  }

  .mobile-h-3 {
    height: 12px;
  }

  .mobile-w-4 {
    width: 16px;
  }

  .mobile-h-4 {
    height: 16px;
  }

  .mobile-w-5 {
    width: 20px;
  }

  .mobile-h-5 {
    height: 20px;
  }

  .mobile-w-6 {
    width: 24px;
  }

  .mobile-h-6 {
    height: 24px;
  }

  .mobile-w-7 {
    width: 28px;
  }

  .mobile-h-7 {
    height: 28px;
  }

  .mobile-w-8 {
    width: 32px;
  }

  .mobile-h-8 {
    height: 32px;
  }

  .mobile-w-9 {
    width: 36px;
  }

  .mobile-h-9 {
    height: 36px;
  }

  .mobile-w-10 {
    width: 40px;
  }

  .mobile-h-10 {
    height: 40px;
  }

  .mobile-w-11 {
    width: 44px;
  }

  .mobile-h-11 {
    height: 44px;
  }

  .mobile-w-12 {
    width: 48px;
  }

  .mobile-h-12 {
    height: 48px;
  }

  .mobile-w-13 {
    width: 52px;
  }

  .mobile-h-13 {
    height: 52px;
  }

  .mobile-w-14 {
    width: 56px;
  }

  .mobile-h-14 {
    height: 56px;
  }

  .mobile-w-15 {
    width: 60px;
  }

  .mobile-h-15 {
    height: 60px;
  }

  .mobile-w-16 {
    width: 64px;
  }

  .mobile-h-16 {
    height: 64px;
  }

  .mobile-w-17 {
    width: 68px;
  }

  .mobile-h-17 {
    height: 68px;
  }

  .mobile-w-18 {
    width: 72px;
  }

  .mobile-h-18 {
    height: 72px;
  }

  .mobile-w-19 {
    width: 76px;
  }

  .mobile-h-19 {
    height: 76px;
  }

  .mobile-w-20 {
    width: 80px;
  }

  .mobile-h-20 {
    height: 80px;
  }

  .mobile-w-25 {
    width: 100px;
  }

  .mobile-h-25 {
    height: 100px;
  }

  .mobile-m-0 {
    margin: 0px;
  }

  .mobile-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-ml-0 {
    margin-left: 0px;
  }

  .mobile-mr-0 {
    margin-right: 0px;
  }

  .mobile-mb-0 {
    margin-bottom: 0px;
  }

  .mobile-mt-0 {
    margin-top: 0px;
  }

  .mobile-p-0 {
    padding: 0px;
  }

  .mobile-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mobile-pl-0 {
    padding-left: 0px;
  }

  .mobile-pr-0 {
    padding-right: 0px;
  }

  .mobile-pb-0 {
    padding-bottom: 0px;
  }

  .mobile-pt-0 {
    padding-top: 0px;
  }

  .mobile-m-1 {
    margin: 4px;
  }

  .mobile-mx-1 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mobile-my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mobile-ml-1 {
    margin-left: 4px;
  }

  .mobile-mr-1 {
    margin-right: 4px;
  }

  .mobile-mb-1 {
    margin-bottom: 4px;
  }

  .mobile-mt-1 {
    margin-top: 4px;
  }

  .mobile-p-1 {
    padding: 4px;
  }

  .mobile-px-1 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .mobile-py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .mobile-pl-1 {
    padding-left: 4px;
  }

  .mobile-pr-1 {
    padding-right: 4px;
  }

  .mobile-pb-1 {
    padding-bottom: 4px;
  }

  .mobile-pt-1 {
    padding-top: 4px;
  }

  .mobile-m-2 {
    margin: 8px;
  }

  .mobile-mx-2 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mobile-my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mobile-ml-2 {
    margin-left: 8px;
  }

  .mobile-mr-2 {
    margin-right: 8px;
  }

  .mobile-mb-2 {
    margin-bottom: 8px;
  }

  .mobile-mt-2 {
    margin-top: 8px;
  }

  .mobile-p-2 {
    padding: 8px;
  }

  .mobile-px-2 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .mobile-py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .mobile-pl-2 {
    padding-left: 8px;
  }

  .mobile-pr-2 {
    padding-right: 8px;
  }

  .mobile-pb-2 {
    padding-bottom: 8px;
  }

  .mobile-pt-2 {
    padding-top: 8px;
  }

  .mobile-m-3 {
    margin: 12px;
  }

  .mobile-mx-3 {
    margin-left: 12px;
    margin-right: 12px;
  }

  .mobile-my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .mobile-ml-3 {
    margin-left: 12px;
  }

  .mobile-mr-3 {
    margin-right: 12px;
  }

  .mobile-mb-3 {
    margin-bottom: 12px;
  }

  .mobile-mt-3 {
    margin-top: 12px;
  }

  .mobile-p-3 {
    padding: 12px;
  }

  .mobile-px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }

  .mobile-py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .mobile-pl-3 {
    padding-left: 12px;
  }

  .mobile-pr-3 {
    padding-right: 12px;
  }

  .mobile-pb-3 {
    padding-bottom: 12px;
  }

  .mobile-pt-3 {
    padding-top: 12px;
  }

  .mobile-m-4 {
    margin: 16px;
  }

  .mobile-mx-4 {
    margin-left: 16px;
    margin-right: 16px;
  }

  .mobile-my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .mobile-ml-4 {
    margin-left: 16px;
  }

  .mobile-mr-4 {
    margin-right: 16px;
  }

  .mobile-mb-4 {
    margin-bottom: 16px;
  }

  .mobile-mt-4 {
    margin-top: 16px;
  }

  .mobile-p-4 {
    padding: 16px;
  }

  .mobile-px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .mobile-py-4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .mobile-pl-4 {
    padding-left: 16px;
  }

  .mobile-pr-4 {
    padding-right: 16px;
  }

  .mobile-pb-4 {
    padding-bottom: 16px;
  }

  .mobile-pt-4 {
    padding-top: 16px;
  }

  .mobile-m-5 {
    margin: 20px;
  }

  .mobile-mx-5 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mobile-my-5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mobile-ml-5 {
    margin-left: 20px;
  }

  .mobile-mr-5 {
    margin-right: 20px;
  }

  .mobile-mb-5 {
    margin-bottom: 20px;
  }

  .mobile-mt-5 {
    margin-top: 20px;
  }

  .mobile-p-5 {
    padding: 20px;
  }

  .mobile-px-5 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mobile-pl-5 {
    padding-left: 20px;
  }

  .mobile-pr-5 {
    padding-right: 20px;
  }

  .mobile-pb-5 {
    padding-bottom: 20px;
  }

  .mobile-pt-5 {
    padding-top: 20px;
  }

  .mobile-m-6 {
    margin: 24px;
  }

  .mobile-mx-6 {
    margin-left: 24px;
    margin-right: 24px;
  }

  .mobile-my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .mobile-ml-6 {
    margin-left: 24px;
  }

  .mobile-mr-6 {
    margin-right: 24px;
  }

  .mobile-mb-6 {
    margin-bottom: 24px;
  }

  .mobile-mt-6 {
    margin-top: 24px;
  }

  .mobile-p-6 {
    padding: 24px;
  }

  .mobile-px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .mobile-py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .mobile-pl-6 {
    padding-left: 24px;
  }

  .mobile-pr-6 {
    padding-right: 24px;
  }

  .mobile-pb-6 {
    padding-bottom: 24px;
  }

  .mobile-pt-6 {
    padding-top: 24px;
  }

  .mobile-m-7 {
    margin: 28px;
  }

  .mobile-mx-7 {
    margin-left: 28px;
    margin-right: 28px;
  }

  .mobile-my-7 {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .mobile-ml-7 {
    margin-left: 28px;
  }

  .mobile-mr-7 {
    margin-right: 28px;
  }

  .mobile-mb-7 {
    margin-bottom: 28px;
  }

  .mobile-mt-7 {
    margin-top: 28px;
  }

  .mobile-p-7 {
    padding: 28px;
  }

  .mobile-px-7 {
    padding-left: 28px;
    padding-right: 28px;
  }

  .mobile-py-7 {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .mobile-pl-7 {
    padding-left: 28px;
  }

  .mobile-pr-7 {
    padding-right: 28px;
  }

  .mobile-pb-7 {
    padding-bottom: 28px;
  }

  .mobile-pt-7 {
    padding-top: 28px;
  }

  .mobile-m-8 {
    margin: 32px;
  }

  .mobile-mx-8 {
    margin-left: 32px;
    margin-right: 32px;
  }

  .mobile-my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .mobile-ml-8 {
    margin-left: 32px;
  }

  .mobile-mr-8 {
    margin-right: 32px;
  }

  .mobile-mb-8 {
    margin-bottom: 32px;
  }

  .mobile-mt-8 {
    margin-top: 32px;
  }

  .mobile-p-8 {
    padding: 32px;
  }

  .mobile-px-8 {
    padding-left: 32px;
    padding-right: 32px;
  }

  .mobile-py-8 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .mobile-pl-8 {
    padding-left: 32px;
  }

  .mobile-pr-8 {
    padding-right: 32px;
  }

  .mobile-pb-8 {
    padding-bottom: 32px;
  }

  .mobile-pt-8 {
    padding-top: 32px;
  }

  .mobile-m-9 {
    margin: 36px;
  }

  .mobile-mx-9 {
    margin-left: 36px;
    margin-right: 36px;
  }

  .mobile-my-9 {
    margin-top: 36px;
    margin-bottom: 36px;
  }

  .mobile-ml-9 {
    margin-left: 36px;
  }

  .mobile-mr-9 {
    margin-right: 36px;
  }

  .mobile-mb-9 {
    margin-bottom: 36px;
  }

  .mobile-mt-9 {
    margin-top: 36px;
  }

  .mobile-p-9 {
    padding: 36px;
  }

  .mobile-px-9 {
    padding-left: 36px;
    padding-right: 36px;
  }

  .mobile-py-9 {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .mobile-pl-9 {
    padding-left: 36px;
  }

  .mobile-pr-9 {
    padding-right: 36px;
  }

  .mobile-pb-9 {
    padding-bottom: 36px;
  }

  .mobile-pt-9 {
    padding-top: 36px;
  }

  .mobile-m-10 {
    margin: 40px;
  }

  .mobile-mx-10 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mobile-my-10 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mobile-ml-10 {
    margin-left: 40px;
  }

  .mobile-mr-10 {
    margin-right: 40px;
  }

  .mobile-mb-10 {
    margin-bottom: 40px;
  }

  .mobile-mt-10 {
    margin-top: 40px;
  }

  .mobile-p-10 {
    padding: 40px;
  }

  .mobile-px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .mobile-py-10 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .mobile-pl-10 {
    padding-left: 40px;
  }

  .mobile-pr-10 {
    padding-right: 40px;
  }

  .mobile-pb-10 {
    padding-bottom: 40px;
  }

  .mobile-pt-10 {
    padding-top: 40px;
  }

  .mobile-m-11 {
    margin: 44px;
  }

  .mobile-mx-11 {
    margin-left: 44px;
    margin-right: 44px;
  }

  .mobile-my-11 {
    margin-top: 44px;
    margin-bottom: 44px;
  }

  .mobile-ml-11 {
    margin-left: 44px;
  }

  .mobile-mr-11 {
    margin-right: 44px;
  }

  .mobile-mb-11 {
    margin-bottom: 44px;
  }

  .mobile-mt-11 {
    margin-top: 44px;
  }

  .mobile-p-11 {
    padding: 44px;
  }

  .mobile-px-11 {
    padding-left: 44px;
    padding-right: 44px;
  }

  .mobile-py-11 {
    padding-top: 44px;
    padding-bottom: 44px;
  }

  .mobile-pl-11 {
    padding-left: 44px;
  }

  .mobile-pr-11 {
    padding-right: 44px;
  }

  .mobile-pb-11 {
    padding-bottom: 44px;
  }

  .mobile-pt-11 {
    padding-top: 44px;
  }

  .mobile-m-12 {
    margin: 48px;
  }

  .mobile-mx-12 {
    margin-left: 48px;
    margin-right: 48px;
  }

  .mobile-my-12 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .mobile-ml-12 {
    margin-left: 48px;
  }

  .mobile-mr-12 {
    margin-right: 48px;
  }

  .mobile-mb-12 {
    margin-bottom: 48px;
  }

  .mobile-mt-12 {
    margin-top: 48px;
  }

  .mobile-p-12 {
    padding: 48px;
  }

  .mobile-px-12 {
    padding-left: 48px;
    padding-right: 48px;
  }

  .mobile-py-12 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .mobile-pl-12 {
    padding-left: 48px;
  }

  .mobile-pr-12 {
    padding-right: 48px;
  }

  .mobile-pb-12 {
    padding-bottom: 48px;
  }

  .mobile-pt-12 {
    padding-top: 48px;
  }

  .mobile-m-13 {
    margin: 52px;
  }

  .mobile-mx-13 {
    margin-left: 52px;
    margin-right: 52px;
  }

  .mobile-my-13 {
    margin-top: 52px;
    margin-bottom: 52px;
  }

  .mobile-ml-13 {
    margin-left: 52px;
  }

  .mobile-mr-13 {
    margin-right: 52px;
  }

  .mobile-mb-13 {
    margin-bottom: 52px;
  }

  .mobile-mt-13 {
    margin-top: 52px;
  }

  .mobile-p-13 {
    padding: 52px;
  }

  .mobile-px-13 {
    padding-left: 52px;
    padding-right: 52px;
  }

  .mobile-py-13 {
    padding-top: 52px;
    padding-bottom: 52px;
  }

  .mobile-pl-13 {
    padding-left: 52px;
  }

  .mobile-pr-13 {
    padding-right: 52px;
  }

  .mobile-pb-13 {
    padding-bottom: 52px;
  }

  .mobile-pt-13 {
    padding-top: 52px;
  }

  .mobile-m-14 {
    margin: 56px;
  }

  .mobile-mx-14 {
    margin-left: 56px;
    margin-right: 56px;
  }

  .mobile-my-14 {
    margin-top: 56px;
    margin-bottom: 56px;
  }

  .mobile-ml-14 {
    margin-left: 56px;
  }

  .mobile-mr-14 {
    margin-right: 56px;
  }

  .mobile-mb-14 {
    margin-bottom: 56px;
  }

  .mobile-mt-14 {
    margin-top: 56px;
  }

  .mobile-p-14 {
    padding: 56px;
  }

  .mobile-px-14 {
    padding-left: 56px;
    padding-right: 56px;
  }

  .mobile-py-14 {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .mobile-pl-14 {
    padding-left: 56px;
  }

  .mobile-pr-14 {
    padding-right: 56px;
  }

  .mobile-pb-14 {
    padding-bottom: 56px;
  }

  .mobile-pt-14 {
    padding-top: 56px;
  }

  .mobile-m-15 {
    margin: 60px;
  }

  .mobile-mx-15 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mobile-my-15 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mobile-ml-15 {
    margin-left: 60px;
  }

  .mobile-mr-15 {
    margin-right: 60px;
  }

  .mobile-mb-15 {
    margin-bottom: 60px;
  }

  .mobile-mt-15 {
    margin-top: 60px;
  }

  .mobile-p-15 {
    padding: 60px;
  }

  .mobile-px-15 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .mobile-py-15 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .mobile-pl-15 {
    padding-left: 60px;
  }

  .mobile-pr-15 {
    padding-right: 60px;
  }

  .mobile-pb-15 {
    padding-bottom: 60px;
  }

  .mobile-pt-15 {
    padding-top: 60px;
  }

  .mobile-m-16 {
    margin: 64px;
  }

  .mobile-mx-16 {
    margin-left: 64px;
    margin-right: 64px;
  }

  .mobile-my-16 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .mobile-ml-16 {
    margin-left: 64px;
  }

  .mobile-mr-16 {
    margin-right: 64px;
  }

  .mobile-mb-16 {
    margin-bottom: 64px;
  }

  .mobile-mt-16 {
    margin-top: 64px;
  }

  .mobile-p-16 {
    padding: 64px;
  }

  .mobile-px-16 {
    padding-left: 64px;
    padding-right: 64px;
  }

  .mobile-py-16 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .mobile-pl-16 {
    padding-left: 64px;
  }

  .mobile-pr-16 {
    padding-right: 64px;
  }

  .mobile-pb-16 {
    padding-bottom: 64px;
  }

  .mobile-pt-16 {
    padding-top: 64px;
  }

  .mobile-m-17 {
    margin: 68px;
  }

  .mobile-mx-17 {
    margin-left: 68px;
    margin-right: 68px;
  }

  .mobile-my-17 {
    margin-top: 68px;
    margin-bottom: 68px;
  }

  .mobile-ml-17 {
    margin-left: 68px;
  }

  .mobile-mr-17 {
    margin-right: 68px;
  }

  .mobile-mb-17 {
    margin-bottom: 68px;
  }

  .mobile-mt-17 {
    margin-top: 68px;
  }

  .mobile-p-17 {
    padding: 68px;
  }

  .mobile-px-17 {
    padding-left: 68px;
    padding-right: 68px;
  }

  .mobile-py-17 {
    padding-top: 68px;
    padding-bottom: 68px;
  }

  .mobile-pl-17 {
    padding-left: 68px;
  }

  .mobile-pr-17 {
    padding-right: 68px;
  }

  .mobile-pb-17 {
    padding-bottom: 68px;
  }

  .mobile-pt-17 {
    padding-top: 68px;
  }

  .mobile-m-18 {
    margin: 72px;
  }

  .mobile-mx-18 {
    margin-left: 72px;
    margin-right: 72px;
  }

  .mobile-my-18 {
    margin-top: 72px;
    margin-bottom: 72px;
  }

  .mobile-ml-18 {
    margin-left: 72px;
  }

  .mobile-mr-18 {
    margin-right: 72px;
  }

  .mobile-mb-18 {
    margin-bottom: 72px;
  }

  .mobile-mt-18 {
    margin-top: 72px;
  }

  .mobile-p-18 {
    padding: 72px;
  }

  .mobile-px-18 {
    padding-left: 72px;
    padding-right: 72px;
  }

  .mobile-py-18 {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .mobile-pl-18 {
    padding-left: 72px;
  }

  .mobile-pr-18 {
    padding-right: 72px;
  }

  .mobile-pb-18 {
    padding-bottom: 72px;
  }

  .mobile-pt-18 {
    padding-top: 72px;
  }

  .mobile-m-19 {
    margin: 76px;
  }

  .mobile-mx-19 {
    margin-left: 76px;
    margin-right: 76px;
  }

  .mobile-my-19 {
    margin-top: 76px;
    margin-bottom: 76px;
  }

  .mobile-ml-19 {
    margin-left: 76px;
  }

  .mobile-mr-19 {
    margin-right: 76px;
  }

  .mobile-mb-19 {
    margin-bottom: 76px;
  }

  .mobile-mt-19 {
    margin-top: 76px;
  }

  .mobile-p-19 {
    padding: 76px;
  }

  .mobile-px-19 {
    padding-left: 76px;
    padding-right: 76px;
  }

  .mobile-py-19 {
    padding-top: 76px;
    padding-bottom: 76px;
  }

  .mobile-pl-19 {
    padding-left: 76px;
  }

  .mobile-pr-19 {
    padding-right: 76px;
  }

  .mobile-pb-19 {
    padding-bottom: 76px;
  }

  .mobile-pt-19 {
    padding-top: 76px;
  }

  .mobile-m-20 {
    margin: 80px;
  }

  .mobile-mx-20 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mobile-my-20 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mobile-ml-20 {
    margin-left: 80px;
  }

  .mobile-mr-20 {
    margin-right: 80px;
  }

  .mobile-mb-20 {
    margin-bottom: 80px;
  }

  .mobile-mt-20 {
    margin-top: 80px;
  }

  .mobile-p-20 {
    padding: 80px;
  }

  .mobile-px-20 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .mobile-py-20 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .mobile-pl-20 {
    padding-left: 80px;
  }

  .mobile-pr-20 {
    padding-right: 80px;
  }

  .mobile-pb-20 {
    padding-bottom: 80px;
  }

  .mobile-pt-20 {
    padding-top: 80px;
  }

  .mobile-m-25 {
    margin: 100px;
  }

  .mobile-mx-25 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mobile-my-25 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mobile-ml-25 {
    margin-left: 100px;
  }

  .mobile-mr-25 {
    margin-right: 100px;
  }

  .mobile-mb-25 {
    margin-bottom: 100px;
  }

  .mobile-mt-25 {
    margin-top: 100px;
  }

  .mobile-p-25 {
    padding: 100px;
  }

  .mobile-px-25 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .mobile-py-25 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .mobile-pl-25 {
    padding-left: 100px;
  }

  .mobile-pr-25 {
    padding-right: 100px;
  }

  .mobile-pb-25 {
    padding-bottom: 100px;
  }

  .mobile-pt-25 {
    padding-top: 100px;
  }

  .mobile-d-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
  }

  .mobile-d-block {
    display: -webkit-block;
    display: -moz-block;
    display: -ms-blockbox;
    display: -ms-block;
    display: block;
  }

  .mobile-d-grid {
    display: -webkit-grid;
    display: -moz-grid;
    display: -ms-gridbox;
    display: -ms-grid;
    display: grid;
  }

  .mobile-d-inline {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: -ms-inline-flex;
    display: inline-flex;
  }

  .mobile-d-none {
    display: -webkit-none;
    display: -moz-none;
    display: -ms-nonebox;
    display: -ms-none;
    display: none;
  }

  .mobile-direction-row {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .mobile-directionrow-reverse {
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .mobile-direction-column {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .mobile-direction-column-reverse {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .mobile-wrap-wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .mobile-wrap-nowrap {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .mobile-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -moz-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }

  .mobile-justify-flex-start {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }

  .mobile-justify-flex-end {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
  }

  .mobile-justify-center {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }

  .mobile-justify-space-between {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
  }

  .mobile-justify-space-around {
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
  }

  .mobile-justify-self-flex-start {
    -webkit-justify-self: flex-start;
    -moz-justify-self: flex-start;
    -ms-justify-self: flex-start;
    justify-self: flex-start;
  }

  .mobile-justify-self-flex-end {
    -webkit-justify-self: flex-end;
    -moz-justify-self: flex-end;
    -ms-justify-self: flex-end;
    justify-self: flex-end;
  }

  .mobile-justify-self-center {
    -webkit-justify-self: center;
    -moz-justify-self: center;
    -ms-justify-self: center;
    justify-self: center;
  }

  .mobile-justify-self-space-between {
    -webkit-justify-self: space-between;
    -moz-justify-self: space-between;
    -ms-justify-self: space-between;
    justify-self: space-between;
  }

  .mobile-justify-self-space-around {
    -webkit-justify-self: space-around;
    -moz-justify-self: space-around;
    -ms-justify-self: space-around;
    justify-self: space-around;
  }

  .mobile-align-content-flex-start {
    -webkit-align-content: flex-start;
    -moz-align-content: flex-start;
    -ms-align-content: flex-start;
    align-content: flex-start;
  }

  .mobile-align-content-flex-end {
    -webkit-align-content: flex-end;
    -moz-align-content: flex-end;
    -ms-align-content: flex-end;
    align-content: flex-end;
  }

  .mobile-align-content-center {
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
  }

  .mobile-align-content-space-between {
    -webkit-align-content: space-between;
    -moz-align-content: space-between;
    -ms-align-content: space-between;
    align-content: space-between;
  }

  .mobile-align-content-space-around {
    -webkit-align-content: space-around;
    -moz-align-content: space-around;
    -ms-align-content: space-around;
    align-content: space-around;
  }

  .mobile-align-content-stretch {
    -webkit-align-content: stretch;
    -moz-align-content: stretch;
    -ms-align-content: stretch;
    align-content: stretch;
  }

  .mobile-align-items-flex-start {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }

  .mobile-align-items-flex-end {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
  }

  .mobile-align-items-center {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }

  .mobile-align-items-baseline {
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
  }

  .mobile-align-items-stretch {
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
  }

  .mobile-order-0 {
    -webkit-order: 0;
    -moz-order: 0;
    -ms-order: 0;
    order: 0;
  }

  .mobile-order-1 {
    -webkit-order: 1;
    -moz-order: 1;
    -ms-order: 1;
    order: 1;
  }

  .mobile-order-2 {
    -webkit-order: 2;
    -moz-order: 2;
    -ms-order: 2;
    order: 2;
  }

  .mobile-order-3 {
    -webkit-order: 3;
    -moz-order: 3;
    -ms-order: 3;
    order: 3;
  }

  .mobile-order-4 {
    -webkit-order: 4;
    -moz-order: 4;
    -ms-order: 4;
    order: 4;
  }

  .mobile-order-5 {
    -webkit-order: 5;
    -moz-order: 5;
    -ms-order: 5;
    order: 5;
  }

  .mobile-order-6 {
    -webkit-order: 6;
    -moz-order: 6;
    -ms-order: 6;
    order: 6;
  }

  .mobile-order-7 {
    -webkit-order: 7;
    -moz-order: 7;
    -ms-order: 7;
    order: 7;
  }

  .mobile-order-8 {
    -webkit-order: 8;
    -moz-order: 8;
    -ms-order: 8;
    order: 8;
  }

  .mobile-order-9 {
    -webkit-order: 9;
    -moz-order: 9;
    -ms-order: 9;
    order: 9;
  }

  .mobile-order-10 {
    -webkit-order: 10;
    -moz-order: 10;
    -ms-order: 10;
    order: 10;
  }

  .mobile-grow-0 {
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-grow: 0;
    flex-grow: 0;
  }

  .mobile-grow-1 {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
  }

  .mobile-grow-2 {
    -webkit-flex-grow: 2;
    -moz-flex-grow: 2;
    -ms-flex-grow: 2;
    flex-grow: 2;
  }

  .mobile-grow-3 {
    -webkit-flex-grow: 3;
    -moz-flex-grow: 3;
    -ms-flex-grow: 3;
    flex-grow: 3;
  }

  .mobile-grow-4 {
    -webkit-flex-grow: 4;
    -moz-flex-grow: 4;
    -ms-flex-grow: 4;
    flex-grow: 4;
  }

  .mobile-grow-5 {
    -webkit-flex-grow: 5;
    -moz-flex-grow: 5;
    -ms-flex-grow: 5;
    flex-grow: 5;
  }

  .mobile-grow-6 {
    -webkit-flex-grow: 6;
    -moz-flex-grow: 6;
    -ms-flex-grow: 6;
    flex-grow: 6;
  }

  .mobile-grow-7 {
    -webkit-flex-grow: 7;
    -moz-flex-grow: 7;
    -ms-flex-grow: 7;
    flex-grow: 7;
  }

  .mobile-grow-8 {
    -webkit-flex-grow: 8;
    -moz-flex-grow: 8;
    -ms-flex-grow: 8;
    flex-grow: 8;
  }

  .mobile-grow-9 {
    -webkit-flex-grow: 9;
    -moz-flex-grow: 9;
    -ms-flex-grow: 9;
    flex-grow: 9;
  }

  .mobile-grow-10 {
    -webkit-flex-grow: 10;
    -moz-flex-grow: 10;
    -ms-flex-grow: 10;
    flex-grow: 10;
  }

  .mobile-shrink-0 {
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
  }

  .mobile-shrink-1 {
    -webkit-flex-shrink: 1;
    -moz-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
  }

  .mobile-shrink-2 {
    -webkit-flex-shrink: 2;
    -moz-flex-shrink: 2;
    -ms-flex-shrink: 2;
    flex-shrink: 2;
  }

  .mobile-shrink-3 {
    -webkit-flex-shrink: 3;
    -moz-flex-shrink: 3;
    -ms-flex-shrink: 3;
    flex-shrink: 3;
  }

  .mobile-shrink-4 {
    -webkit-flex-shrink: 4;
    -moz-flex-shrink: 4;
    -ms-flex-shrink: 4;
    flex-shrink: 4;
  }

  .mobile-shrink-5 {
    -webkit-flex-shrink: 5;
    -moz-flex-shrink: 5;
    -ms-flex-shrink: 5;
    flex-shrink: 5;
  }

  .mobile-shrink-6 {
    -webkit-flex-shrink: 6;
    -moz-flex-shrink: 6;
    -ms-flex-shrink: 6;
    flex-shrink: 6;
  }

  .mobile-shrink-7 {
    -webkit-flex-shrink: 7;
    -moz-flex-shrink: 7;
    -ms-flex-shrink: 7;
    flex-shrink: 7;
  }

  .mobile-shrink-8 {
    -webkit-flex-shrink: 8;
    -moz-flex-shrink: 8;
    -ms-flex-shrink: 8;
    flex-shrink: 8;
  }

  .mobile-shrink-9 {
    -webkit-flex-shrink: 9;
    -moz-flex-shrink: 9;
    -ms-flex-shrink: 9;
    flex-shrink: 9;
  }

  .mobile-shrink-10 {
    -webkit-flex-shrink: 10;
    -moz-flex-shrink: 10;
    -ms-flex-shrink: 10;
    flex-shrink: 10;
  }

  .mobile-align-self-flex-start {
    -webkit-align-self: flex-start;
    -moz-align-self: flex-start;
    -ms-align-self: flex-start;
    align-self: flex-start;
  }

  .mobile-align-self-flex-end {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-align-self: flex-end;
    align-self: flex-end;
  }

  .mobile-align-self-center {
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-align-self: center;
    align-self: center;
  }

  .mobile-align-self-baseline {
    -webkit-align-self: baseline;
    -moz-align-self: baseline;
    -ms-align-self: baseline;
    align-self: baseline;
  }

  .mobile-align-self-stretch {
    -webkit-align-self: stretch;
    -moz-align-self: stretch;
    -ms-align-self: stretch;
    align-self: stretch;
  }
}
@media (min-width: 768px) {
  .tablet-icon-size-0 {
    font-size: 0px;
    line-height: 0px;
    height: 0px;
    width: 0px;
  }

  .tablet-icon-size-1 {
    font-size: 2px;
    line-height: 2px;
    height: 2px;
    width: 2px;
  }

  .tablet-icon-size-2 {
    font-size: 4px;
    line-height: 4px;
    height: 4px;
    width: 4px;
  }

  .tablet-icon-size-3 {
    font-size: 6px;
    line-height: 6px;
    height: 6px;
    width: 6px;
  }

  .tablet-icon-size-4 {
    font-size: 8px;
    line-height: 8px;
    height: 8px;
    width: 8px;
  }

  .tablet-icon-size-5 {
    font-size: 10px;
    line-height: 10px;
    height: 10px;
    width: 10px;
  }

  .tablet-icon-size-6 {
    font-size: 12px;
    line-height: 12px;
    height: 12px;
    width: 12px;
  }

  .tablet-icon-size-7 {
    font-size: 14px;
    line-height: 14px;
    height: 14px;
    width: 14px;
  }

  .tablet-icon-size-8 {
    font-size: 16px;
    line-height: 16px;
    height: 16px;
    width: 16px;
  }

  .tablet-icon-size-9 {
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: 18px;
  }

  .tablet-icon-size-10 {
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    width: 20px;
  }

  .tablet-icon-size-11 {
    font-size: 22px;
    line-height: 22px;
    height: 22px;
    width: 22px;
  }

  .tablet-icon-size-12 {
    font-size: 24px;
    line-height: 24px;
    height: 24px;
    width: 24px;
  }

  .tablet-icon-size-13 {
    font-size: 26px;
    line-height: 26px;
    height: 26px;
    width: 26px;
  }

  .tablet-icon-size-14 {
    font-size: 28px;
    line-height: 28px;
    height: 28px;
    width: 28px;
  }

  .tablet-icon-size-15 {
    font-size: 30px;
    line-height: 30px;
    height: 30px;
    width: 30px;
  }

  .tablet-icon-size-16 {
    font-size: 32px;
    line-height: 32px;
    height: 32px;
    width: 32px;
  }

  .tablet-icon-size-17 {
    font-size: 34px;
    line-height: 34px;
    height: 34px;
    width: 34px;
  }

  .tablet-icon-size-18 {
    font-size: 36px;
    line-height: 36px;
    height: 36px;
    width: 36px;
  }

  .tablet-icon-size-19 {
    font-size: 38px;
    line-height: 38px;
    height: 38px;
    width: 38px;
  }

  .tablet-icon-size-20 {
    font-size: 40px;
    line-height: 40px;
    height: 40px;
    width: 40px;
  }

  .tablet-icon-size-25 {
    font-size: 50px;
    line-height: 50px;
    height: 50px;
    width: 50px;
  }

  .tablet-text-size-0 {
    font-size: 0px;
  }

  .tablet-text-size-1 {
    font-size: 2px;
  }

  .tablet-text-size-2 {
    font-size: 4px;
  }

  .tablet-text-size-3 {
    font-size: 6px;
  }

  .tablet-text-size-4 {
    font-size: 8px;
  }

  .tablet-text-size-5 {
    font-size: 10px;
  }

  .tablet-text-size-6 {
    font-size: 12px;
  }

  .tablet-text-size-7 {
    font-size: 14px;
  }

  .tablet-text-size-8 {
    font-size: 16px;
  }

  .tablet-text-size-9 {
    font-size: 18px;
  }

  .tablet-text-size-10 {
    font-size: 20px;
  }

  .tablet-text-size-11 {
    font-size: 22px;
  }

  .tablet-text-size-12 {
    font-size: 24px;
  }

  .tablet-text-size-13 {
    font-size: 26px;
  }

  .tablet-text-size-14 {
    font-size: 28px;
  }

  .tablet-text-size-15 {
    font-size: 30px;
  }

  .tablet-text-size-16 {
    font-size: 32px;
  }

  .tablet-text-size-17 {
    font-size: 34px;
  }

  .tablet-text-size-18 {
    font-size: 36px;
  }

  .tablet-text-size-19 {
    font-size: 38px;
  }

  .tablet-text-size-20 {
    font-size: 40px;
  }

  .tablet-text-size-25 {
    font-size: 50px;
  }

  .tablet-w-0 {
    width: 0px;
  }

  .tablet-h-0 {
    height: 0px;
  }

  .tablet-w-1 {
    width: 4px;
  }

  .tablet-h-1 {
    height: 4px;
  }

  .tablet-w-2 {
    width: 8px;
  }

  .tablet-h-2 {
    height: 8px;
  }

  .tablet-w-3 {
    width: 12px;
  }

  .tablet-h-3 {
    height: 12px;
  }

  .tablet-w-4 {
    width: 16px;
  }

  .tablet-h-4 {
    height: 16px;
  }

  .tablet-w-5 {
    width: 20px;
  }

  .tablet-h-5 {
    height: 20px;
  }

  .tablet-w-6 {
    width: 24px;
  }

  .tablet-h-6 {
    height: 24px;
  }

  .tablet-w-7 {
    width: 28px;
  }

  .tablet-h-7 {
    height: 28px;
  }

  .tablet-w-8 {
    width: 32px;
  }

  .tablet-h-8 {
    height: 32px;
  }

  .tablet-w-9 {
    width: 36px;
  }

  .tablet-h-9 {
    height: 36px;
  }

  .tablet-w-10 {
    width: 40px;
  }

  .tablet-h-10 {
    height: 40px;
  }

  .tablet-w-11 {
    width: 44px;
  }

  .tablet-h-11 {
    height: 44px;
  }

  .tablet-w-12 {
    width: 48px;
  }

  .tablet-h-12 {
    height: 48px;
  }

  .tablet-w-13 {
    width: 52px;
  }

  .tablet-h-13 {
    height: 52px;
  }

  .tablet-w-14 {
    width: 56px;
  }

  .tablet-h-14 {
    height: 56px;
  }

  .tablet-w-15 {
    width: 60px;
  }

  .tablet-h-15 {
    height: 60px;
  }

  .tablet-w-16 {
    width: 64px;
  }

  .tablet-h-16 {
    height: 64px;
  }

  .tablet-w-17 {
    width: 68px;
  }

  .tablet-h-17 {
    height: 68px;
  }

  .tablet-w-18 {
    width: 72px;
  }

  .tablet-h-18 {
    height: 72px;
  }

  .tablet-w-19 {
    width: 76px;
  }

  .tablet-h-19 {
    height: 76px;
  }

  .tablet-w-20 {
    width: 80px;
  }

  .tablet-h-20 {
    height: 80px;
  }

  .tablet-w-25 {
    width: 100px;
  }

  .tablet-h-25 {
    height: 100px;
  }

  .tablet-m-0 {
    margin: 0px;
  }

  .tablet-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet-ml-0 {
    margin-left: 0px;
  }

  .tablet-mr-0 {
    margin-right: 0px;
  }

  .tablet-mb-0 {
    margin-bottom: 0px;
  }

  .tablet-mt-0 {
    margin-top: 0px;
  }

  .tablet-p-0 {
    padding: 0px;
  }

  .tablet-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .tablet-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .tablet-pl-0 {
    padding-left: 0px;
  }

  .tablet-pr-0 {
    padding-right: 0px;
  }

  .tablet-pb-0 {
    padding-bottom: 0px;
  }

  .tablet-pt-0 {
    padding-top: 0px;
  }

  .tablet-m-1 {
    margin: 4px;
  }

  .tablet-mx-1 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .tablet-my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .tablet-ml-1 {
    margin-left: 4px;
  }

  .tablet-mr-1 {
    margin-right: 4px;
  }

  .tablet-mb-1 {
    margin-bottom: 4px;
  }

  .tablet-mt-1 {
    margin-top: 4px;
  }

  .tablet-p-1 {
    padding: 4px;
  }

  .tablet-px-1 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .tablet-py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .tablet-pl-1 {
    padding-left: 4px;
  }

  .tablet-pr-1 {
    padding-right: 4px;
  }

  .tablet-pb-1 {
    padding-bottom: 4px;
  }

  .tablet-pt-1 {
    padding-top: 4px;
  }

  .tablet-m-2 {
    margin: 8px;
  }

  .tablet-mx-2 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .tablet-my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .tablet-ml-2 {
    margin-left: 8px;
  }

  .tablet-mr-2 {
    margin-right: 8px;
  }

  .tablet-mb-2 {
    margin-bottom: 8px;
  }

  .tablet-mt-2 {
    margin-top: 8px;
  }

  .tablet-p-2 {
    padding: 8px;
  }

  .tablet-px-2 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .tablet-py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .tablet-pl-2 {
    padding-left: 8px;
  }

  .tablet-pr-2 {
    padding-right: 8px;
  }

  .tablet-pb-2 {
    padding-bottom: 8px;
  }

  .tablet-pt-2 {
    padding-top: 8px;
  }

  .tablet-m-3 {
    margin: 12px;
  }

  .tablet-mx-3 {
    margin-left: 12px;
    margin-right: 12px;
  }

  .tablet-my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .tablet-ml-3 {
    margin-left: 12px;
  }

  .tablet-mr-3 {
    margin-right: 12px;
  }

  .tablet-mb-3 {
    margin-bottom: 12px;
  }

  .tablet-mt-3 {
    margin-top: 12px;
  }

  .tablet-p-3 {
    padding: 12px;
  }

  .tablet-px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }

  .tablet-py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .tablet-pl-3 {
    padding-left: 12px;
  }

  .tablet-pr-3 {
    padding-right: 12px;
  }

  .tablet-pb-3 {
    padding-bottom: 12px;
  }

  .tablet-pt-3 {
    padding-top: 12px;
  }

  .tablet-m-4 {
    margin: 16px;
  }

  .tablet-mx-4 {
    margin-left: 16px;
    margin-right: 16px;
  }

  .tablet-my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .tablet-ml-4 {
    margin-left: 16px;
  }

  .tablet-mr-4 {
    margin-right: 16px;
  }

  .tablet-mb-4 {
    margin-bottom: 16px;
  }

  .tablet-mt-4 {
    margin-top: 16px;
  }

  .tablet-p-4 {
    padding: 16px;
  }

  .tablet-px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .tablet-py-4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .tablet-pl-4 {
    padding-left: 16px;
  }

  .tablet-pr-4 {
    padding-right: 16px;
  }

  .tablet-pb-4 {
    padding-bottom: 16px;
  }

  .tablet-pt-4 {
    padding-top: 16px;
  }

  .tablet-m-5 {
    margin: 20px;
  }

  .tablet-mx-5 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .tablet-my-5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .tablet-ml-5 {
    margin-left: 20px;
  }

  .tablet-mr-5 {
    margin-right: 20px;
  }

  .tablet-mb-5 {
    margin-bottom: 20px;
  }

  .tablet-mt-5 {
    margin-top: 20px;
  }

  .tablet-p-5 {
    padding: 20px;
  }

  .tablet-px-5 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .tablet-py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .tablet-pl-5 {
    padding-left: 20px;
  }

  .tablet-pr-5 {
    padding-right: 20px;
  }

  .tablet-pb-5 {
    padding-bottom: 20px;
  }

  .tablet-pt-5 {
    padding-top: 20px;
  }

  .tablet-m-6 {
    margin: 24px;
  }

  .tablet-mx-6 {
    margin-left: 24px;
    margin-right: 24px;
  }

  .tablet-my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .tablet-ml-6 {
    margin-left: 24px;
  }

  .tablet-mr-6 {
    margin-right: 24px;
  }

  .tablet-mb-6 {
    margin-bottom: 24px;
  }

  .tablet-mt-6 {
    margin-top: 24px;
  }

  .tablet-p-6 {
    padding: 24px;
  }

  .tablet-px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .tablet-py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .tablet-pl-6 {
    padding-left: 24px;
  }

  .tablet-pr-6 {
    padding-right: 24px;
  }

  .tablet-pb-6 {
    padding-bottom: 24px;
  }

  .tablet-pt-6 {
    padding-top: 24px;
  }

  .tablet-m-7 {
    margin: 28px;
  }

  .tablet-mx-7 {
    margin-left: 28px;
    margin-right: 28px;
  }

  .tablet-my-7 {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .tablet-ml-7 {
    margin-left: 28px;
  }

  .tablet-mr-7 {
    margin-right: 28px;
  }

  .tablet-mb-7 {
    margin-bottom: 28px;
  }

  .tablet-mt-7 {
    margin-top: 28px;
  }

  .tablet-p-7 {
    padding: 28px;
  }

  .tablet-px-7 {
    padding-left: 28px;
    padding-right: 28px;
  }

  .tablet-py-7 {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .tablet-pl-7 {
    padding-left: 28px;
  }

  .tablet-pr-7 {
    padding-right: 28px;
  }

  .tablet-pb-7 {
    padding-bottom: 28px;
  }

  .tablet-pt-7 {
    padding-top: 28px;
  }

  .tablet-m-8 {
    margin: 32px;
  }

  .tablet-mx-8 {
    margin-left: 32px;
    margin-right: 32px;
  }

  .tablet-my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .tablet-ml-8 {
    margin-left: 32px;
  }

  .tablet-mr-8 {
    margin-right: 32px;
  }

  .tablet-mb-8 {
    margin-bottom: 32px;
  }

  .tablet-mt-8 {
    margin-top: 32px;
  }

  .tablet-p-8 {
    padding: 32px;
  }

  .tablet-px-8 {
    padding-left: 32px;
    padding-right: 32px;
  }

  .tablet-py-8 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .tablet-pl-8 {
    padding-left: 32px;
  }

  .tablet-pr-8 {
    padding-right: 32px;
  }

  .tablet-pb-8 {
    padding-bottom: 32px;
  }

  .tablet-pt-8 {
    padding-top: 32px;
  }

  .tablet-m-9 {
    margin: 36px;
  }

  .tablet-mx-9 {
    margin-left: 36px;
    margin-right: 36px;
  }

  .tablet-my-9 {
    margin-top: 36px;
    margin-bottom: 36px;
  }

  .tablet-ml-9 {
    margin-left: 36px;
  }

  .tablet-mr-9 {
    margin-right: 36px;
  }

  .tablet-mb-9 {
    margin-bottom: 36px;
  }

  .tablet-mt-9 {
    margin-top: 36px;
  }

  .tablet-p-9 {
    padding: 36px;
  }

  .tablet-px-9 {
    padding-left: 36px;
    padding-right: 36px;
  }

  .tablet-py-9 {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .tablet-pl-9 {
    padding-left: 36px;
  }

  .tablet-pr-9 {
    padding-right: 36px;
  }

  .tablet-pb-9 {
    padding-bottom: 36px;
  }

  .tablet-pt-9 {
    padding-top: 36px;
  }

  .tablet-m-10 {
    margin: 40px;
  }

  .tablet-mx-10 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .tablet-my-10 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .tablet-ml-10 {
    margin-left: 40px;
  }

  .tablet-mr-10 {
    margin-right: 40px;
  }

  .tablet-mb-10 {
    margin-bottom: 40px;
  }

  .tablet-mt-10 {
    margin-top: 40px;
  }

  .tablet-p-10 {
    padding: 40px;
  }

  .tablet-px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .tablet-py-10 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .tablet-pl-10 {
    padding-left: 40px;
  }

  .tablet-pr-10 {
    padding-right: 40px;
  }

  .tablet-pb-10 {
    padding-bottom: 40px;
  }

  .tablet-pt-10 {
    padding-top: 40px;
  }

  .tablet-m-11 {
    margin: 44px;
  }

  .tablet-mx-11 {
    margin-left: 44px;
    margin-right: 44px;
  }

  .tablet-my-11 {
    margin-top: 44px;
    margin-bottom: 44px;
  }

  .tablet-ml-11 {
    margin-left: 44px;
  }

  .tablet-mr-11 {
    margin-right: 44px;
  }

  .tablet-mb-11 {
    margin-bottom: 44px;
  }

  .tablet-mt-11 {
    margin-top: 44px;
  }

  .tablet-p-11 {
    padding: 44px;
  }

  .tablet-px-11 {
    padding-left: 44px;
    padding-right: 44px;
  }

  .tablet-py-11 {
    padding-top: 44px;
    padding-bottom: 44px;
  }

  .tablet-pl-11 {
    padding-left: 44px;
  }

  .tablet-pr-11 {
    padding-right: 44px;
  }

  .tablet-pb-11 {
    padding-bottom: 44px;
  }

  .tablet-pt-11 {
    padding-top: 44px;
  }

  .tablet-m-12 {
    margin: 48px;
  }

  .tablet-mx-12 {
    margin-left: 48px;
    margin-right: 48px;
  }

  .tablet-my-12 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .tablet-ml-12 {
    margin-left: 48px;
  }

  .tablet-mr-12 {
    margin-right: 48px;
  }

  .tablet-mb-12 {
    margin-bottom: 48px;
  }

  .tablet-mt-12 {
    margin-top: 48px;
  }

  .tablet-p-12 {
    padding: 48px;
  }

  .tablet-px-12 {
    padding-left: 48px;
    padding-right: 48px;
  }

  .tablet-py-12 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .tablet-pl-12 {
    padding-left: 48px;
  }

  .tablet-pr-12 {
    padding-right: 48px;
  }

  .tablet-pb-12 {
    padding-bottom: 48px;
  }

  .tablet-pt-12 {
    padding-top: 48px;
  }

  .tablet-m-13 {
    margin: 52px;
  }

  .tablet-mx-13 {
    margin-left: 52px;
    margin-right: 52px;
  }

  .tablet-my-13 {
    margin-top: 52px;
    margin-bottom: 52px;
  }

  .tablet-ml-13 {
    margin-left: 52px;
  }

  .tablet-mr-13 {
    margin-right: 52px;
  }

  .tablet-mb-13 {
    margin-bottom: 52px;
  }

  .tablet-mt-13 {
    margin-top: 52px;
  }

  .tablet-p-13 {
    padding: 52px;
  }

  .tablet-px-13 {
    padding-left: 52px;
    padding-right: 52px;
  }

  .tablet-py-13 {
    padding-top: 52px;
    padding-bottom: 52px;
  }

  .tablet-pl-13 {
    padding-left: 52px;
  }

  .tablet-pr-13 {
    padding-right: 52px;
  }

  .tablet-pb-13 {
    padding-bottom: 52px;
  }

  .tablet-pt-13 {
    padding-top: 52px;
  }

  .tablet-m-14 {
    margin: 56px;
  }

  .tablet-mx-14 {
    margin-left: 56px;
    margin-right: 56px;
  }

  .tablet-my-14 {
    margin-top: 56px;
    margin-bottom: 56px;
  }

  .tablet-ml-14 {
    margin-left: 56px;
  }

  .tablet-mr-14 {
    margin-right: 56px;
  }

  .tablet-mb-14 {
    margin-bottom: 56px;
  }

  .tablet-mt-14 {
    margin-top: 56px;
  }

  .tablet-p-14 {
    padding: 56px;
  }

  .tablet-px-14 {
    padding-left: 56px;
    padding-right: 56px;
  }

  .tablet-py-14 {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .tablet-pl-14 {
    padding-left: 56px;
  }

  .tablet-pr-14 {
    padding-right: 56px;
  }

  .tablet-pb-14 {
    padding-bottom: 56px;
  }

  .tablet-pt-14 {
    padding-top: 56px;
  }

  .tablet-m-15 {
    margin: 60px;
  }

  .tablet-mx-15 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .tablet-my-15 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .tablet-ml-15 {
    margin-left: 60px;
  }

  .tablet-mr-15 {
    margin-right: 60px;
  }

  .tablet-mb-15 {
    margin-bottom: 60px;
  }

  .tablet-mt-15 {
    margin-top: 60px;
  }

  .tablet-p-15 {
    padding: 60px;
  }

  .tablet-px-15 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .tablet-py-15 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .tablet-pl-15 {
    padding-left: 60px;
  }

  .tablet-pr-15 {
    padding-right: 60px;
  }

  .tablet-pb-15 {
    padding-bottom: 60px;
  }

  .tablet-pt-15 {
    padding-top: 60px;
  }

  .tablet-m-16 {
    margin: 64px;
  }

  .tablet-mx-16 {
    margin-left: 64px;
    margin-right: 64px;
  }

  .tablet-my-16 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .tablet-ml-16 {
    margin-left: 64px;
  }

  .tablet-mr-16 {
    margin-right: 64px;
  }

  .tablet-mb-16 {
    margin-bottom: 64px;
  }

  .tablet-mt-16 {
    margin-top: 64px;
  }

  .tablet-p-16 {
    padding: 64px;
  }

  .tablet-px-16 {
    padding-left: 64px;
    padding-right: 64px;
  }

  .tablet-py-16 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .tablet-pl-16 {
    padding-left: 64px;
  }

  .tablet-pr-16 {
    padding-right: 64px;
  }

  .tablet-pb-16 {
    padding-bottom: 64px;
  }

  .tablet-pt-16 {
    padding-top: 64px;
  }

  .tablet-m-17 {
    margin: 68px;
  }

  .tablet-mx-17 {
    margin-left: 68px;
    margin-right: 68px;
  }

  .tablet-my-17 {
    margin-top: 68px;
    margin-bottom: 68px;
  }

  .tablet-ml-17 {
    margin-left: 68px;
  }

  .tablet-mr-17 {
    margin-right: 68px;
  }

  .tablet-mb-17 {
    margin-bottom: 68px;
  }

  .tablet-mt-17 {
    margin-top: 68px;
  }

  .tablet-p-17 {
    padding: 68px;
  }

  .tablet-px-17 {
    padding-left: 68px;
    padding-right: 68px;
  }

  .tablet-py-17 {
    padding-top: 68px;
    padding-bottom: 68px;
  }

  .tablet-pl-17 {
    padding-left: 68px;
  }

  .tablet-pr-17 {
    padding-right: 68px;
  }

  .tablet-pb-17 {
    padding-bottom: 68px;
  }

  .tablet-pt-17 {
    padding-top: 68px;
  }

  .tablet-m-18 {
    margin: 72px;
  }

  .tablet-mx-18 {
    margin-left: 72px;
    margin-right: 72px;
  }

  .tablet-my-18 {
    margin-top: 72px;
    margin-bottom: 72px;
  }

  .tablet-ml-18 {
    margin-left: 72px;
  }

  .tablet-mr-18 {
    margin-right: 72px;
  }

  .tablet-mb-18 {
    margin-bottom: 72px;
  }

  .tablet-mt-18 {
    margin-top: 72px;
  }

  .tablet-p-18 {
    padding: 72px;
  }

  .tablet-px-18 {
    padding-left: 72px;
    padding-right: 72px;
  }

  .tablet-py-18 {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .tablet-pl-18 {
    padding-left: 72px;
  }

  .tablet-pr-18 {
    padding-right: 72px;
  }

  .tablet-pb-18 {
    padding-bottom: 72px;
  }

  .tablet-pt-18 {
    padding-top: 72px;
  }

  .tablet-m-19 {
    margin: 76px;
  }

  .tablet-mx-19 {
    margin-left: 76px;
    margin-right: 76px;
  }

  .tablet-my-19 {
    margin-top: 76px;
    margin-bottom: 76px;
  }

  .tablet-ml-19 {
    margin-left: 76px;
  }

  .tablet-mr-19 {
    margin-right: 76px;
  }

  .tablet-mb-19 {
    margin-bottom: 76px;
  }

  .tablet-mt-19 {
    margin-top: 76px;
  }

  .tablet-p-19 {
    padding: 76px;
  }

  .tablet-px-19 {
    padding-left: 76px;
    padding-right: 76px;
  }

  .tablet-py-19 {
    padding-top: 76px;
    padding-bottom: 76px;
  }

  .tablet-pl-19 {
    padding-left: 76px;
  }

  .tablet-pr-19 {
    padding-right: 76px;
  }

  .tablet-pb-19 {
    padding-bottom: 76px;
  }

  .tablet-pt-19 {
    padding-top: 76px;
  }

  .tablet-m-20 {
    margin: 80px;
  }

  .tablet-mx-20 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .tablet-my-20 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .tablet-ml-20 {
    margin-left: 80px;
  }

  .tablet-mr-20 {
    margin-right: 80px;
  }

  .tablet-mb-20 {
    margin-bottom: 80px;
  }

  .tablet-mt-20 {
    margin-top: 80px;
  }

  .tablet-p-20 {
    padding: 80px;
  }

  .tablet-px-20 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .tablet-py-20 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .tablet-pl-20 {
    padding-left: 80px;
  }

  .tablet-pr-20 {
    padding-right: 80px;
  }

  .tablet-pb-20 {
    padding-bottom: 80px;
  }

  .tablet-pt-20 {
    padding-top: 80px;
  }

  .tablet-m-25 {
    margin: 100px;
  }

  .tablet-mx-25 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .tablet-my-25 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .tablet-ml-25 {
    margin-left: 100px;
  }

  .tablet-mr-25 {
    margin-right: 100px;
  }

  .tablet-mb-25 {
    margin-bottom: 100px;
  }

  .tablet-mt-25 {
    margin-top: 100px;
  }

  .tablet-p-25 {
    padding: 100px;
  }

  .tablet-px-25 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .tablet-py-25 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .tablet-pl-25 {
    padding-left: 100px;
  }

  .tablet-pr-25 {
    padding-right: 100px;
  }

  .tablet-pb-25 {
    padding-bottom: 100px;
  }

  .tablet-pt-25 {
    padding-top: 100px;
  }

  .tablet-d-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
  }

  .tablet-d-block {
    display: -webkit-block;
    display: -moz-block;
    display: -ms-blockbox;
    display: -ms-block;
    display: block;
  }

  .tablet-d-grid {
    display: -webkit-grid;
    display: -moz-grid;
    display: -ms-gridbox;
    display: -ms-grid;
    display: grid;
  }

  .tablet-d-inline {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: -ms-inline-flex;
    display: inline-flex;
  }

  .tablet-d-none {
    display: -webkit-none;
    display: -moz-none;
    display: -ms-nonebox;
    display: -ms-none;
    display: none;
  }

  .tablet-direction-row {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .tablet-directionrow-reverse {
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .tablet-direction-column {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .tablet-direction-column-reverse {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .tablet-wrap-wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .tablet-wrap-nowrap {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .tablet-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -moz-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }

  .tablet-justify-flex-start {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }

  .tablet-justify-flex-end {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
  }

  .tablet-justify-center {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }

  .tablet-justify-space-between {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
  }

  .tablet-justify-space-around {
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
  }

  .tablet-justify-self-flex-start {
    -webkit-justify-self: flex-start;
    -moz-justify-self: flex-start;
    -ms-justify-self: flex-start;
    justify-self: flex-start;
  }

  .tablet-justify-self-flex-end {
    -webkit-justify-self: flex-end;
    -moz-justify-self: flex-end;
    -ms-justify-self: flex-end;
    justify-self: flex-end;
  }

  .tablet-justify-self-center {
    -webkit-justify-self: center;
    -moz-justify-self: center;
    -ms-justify-self: center;
    justify-self: center;
  }

  .tablet-justify-self-space-between {
    -webkit-justify-self: space-between;
    -moz-justify-self: space-between;
    -ms-justify-self: space-between;
    justify-self: space-between;
  }

  .tablet-justify-self-space-around {
    -webkit-justify-self: space-around;
    -moz-justify-self: space-around;
    -ms-justify-self: space-around;
    justify-self: space-around;
  }

  .tablet-align-content-flex-start {
    -webkit-align-content: flex-start;
    -moz-align-content: flex-start;
    -ms-align-content: flex-start;
    align-content: flex-start;
  }

  .tablet-align-content-flex-end {
    -webkit-align-content: flex-end;
    -moz-align-content: flex-end;
    -ms-align-content: flex-end;
    align-content: flex-end;
  }

  .tablet-align-content-center {
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
  }

  .tablet-align-content-space-between {
    -webkit-align-content: space-between;
    -moz-align-content: space-between;
    -ms-align-content: space-between;
    align-content: space-between;
  }

  .tablet-align-content-space-around {
    -webkit-align-content: space-around;
    -moz-align-content: space-around;
    -ms-align-content: space-around;
    align-content: space-around;
  }

  .tablet-align-content-stretch {
    -webkit-align-content: stretch;
    -moz-align-content: stretch;
    -ms-align-content: stretch;
    align-content: stretch;
  }

  .tablet-align-items-flex-start {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }

  .tablet-align-items-flex-end {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
  }

  .tablet-align-items-center {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }

  .tablet-align-items-baseline {
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
  }

  .tablet-align-items-stretch {
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
  }

  .tablet-order-0 {
    -webkit-order: 0;
    -moz-order: 0;
    -ms-order: 0;
    order: 0;
  }

  .tablet-order-1 {
    -webkit-order: 1;
    -moz-order: 1;
    -ms-order: 1;
    order: 1;
  }

  .tablet-order-2 {
    -webkit-order: 2;
    -moz-order: 2;
    -ms-order: 2;
    order: 2;
  }

  .tablet-order-3 {
    -webkit-order: 3;
    -moz-order: 3;
    -ms-order: 3;
    order: 3;
  }

  .tablet-order-4 {
    -webkit-order: 4;
    -moz-order: 4;
    -ms-order: 4;
    order: 4;
  }

  .tablet-order-5 {
    -webkit-order: 5;
    -moz-order: 5;
    -ms-order: 5;
    order: 5;
  }

  .tablet-order-6 {
    -webkit-order: 6;
    -moz-order: 6;
    -ms-order: 6;
    order: 6;
  }

  .tablet-order-7 {
    -webkit-order: 7;
    -moz-order: 7;
    -ms-order: 7;
    order: 7;
  }

  .tablet-order-8 {
    -webkit-order: 8;
    -moz-order: 8;
    -ms-order: 8;
    order: 8;
  }

  .tablet-order-9 {
    -webkit-order: 9;
    -moz-order: 9;
    -ms-order: 9;
    order: 9;
  }

  .tablet-order-10 {
    -webkit-order: 10;
    -moz-order: 10;
    -ms-order: 10;
    order: 10;
  }

  .tablet-grow-0 {
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-grow: 0;
    flex-grow: 0;
  }

  .tablet-grow-1 {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
  }

  .tablet-grow-2 {
    -webkit-flex-grow: 2;
    -moz-flex-grow: 2;
    -ms-flex-grow: 2;
    flex-grow: 2;
  }

  .tablet-grow-3 {
    -webkit-flex-grow: 3;
    -moz-flex-grow: 3;
    -ms-flex-grow: 3;
    flex-grow: 3;
  }

  .tablet-grow-4 {
    -webkit-flex-grow: 4;
    -moz-flex-grow: 4;
    -ms-flex-grow: 4;
    flex-grow: 4;
  }

  .tablet-grow-5 {
    -webkit-flex-grow: 5;
    -moz-flex-grow: 5;
    -ms-flex-grow: 5;
    flex-grow: 5;
  }

  .tablet-grow-6 {
    -webkit-flex-grow: 6;
    -moz-flex-grow: 6;
    -ms-flex-grow: 6;
    flex-grow: 6;
  }

  .tablet-grow-7 {
    -webkit-flex-grow: 7;
    -moz-flex-grow: 7;
    -ms-flex-grow: 7;
    flex-grow: 7;
  }

  .tablet-grow-8 {
    -webkit-flex-grow: 8;
    -moz-flex-grow: 8;
    -ms-flex-grow: 8;
    flex-grow: 8;
  }

  .tablet-grow-9 {
    -webkit-flex-grow: 9;
    -moz-flex-grow: 9;
    -ms-flex-grow: 9;
    flex-grow: 9;
  }

  .tablet-grow-10 {
    -webkit-flex-grow: 10;
    -moz-flex-grow: 10;
    -ms-flex-grow: 10;
    flex-grow: 10;
  }

  .tablet-shrink-0 {
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
  }

  .tablet-shrink-1 {
    -webkit-flex-shrink: 1;
    -moz-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
  }

  .tablet-shrink-2 {
    -webkit-flex-shrink: 2;
    -moz-flex-shrink: 2;
    -ms-flex-shrink: 2;
    flex-shrink: 2;
  }

  .tablet-shrink-3 {
    -webkit-flex-shrink: 3;
    -moz-flex-shrink: 3;
    -ms-flex-shrink: 3;
    flex-shrink: 3;
  }

  .tablet-shrink-4 {
    -webkit-flex-shrink: 4;
    -moz-flex-shrink: 4;
    -ms-flex-shrink: 4;
    flex-shrink: 4;
  }

  .tablet-shrink-5 {
    -webkit-flex-shrink: 5;
    -moz-flex-shrink: 5;
    -ms-flex-shrink: 5;
    flex-shrink: 5;
  }

  .tablet-shrink-6 {
    -webkit-flex-shrink: 6;
    -moz-flex-shrink: 6;
    -ms-flex-shrink: 6;
    flex-shrink: 6;
  }

  .tablet-shrink-7 {
    -webkit-flex-shrink: 7;
    -moz-flex-shrink: 7;
    -ms-flex-shrink: 7;
    flex-shrink: 7;
  }

  .tablet-shrink-8 {
    -webkit-flex-shrink: 8;
    -moz-flex-shrink: 8;
    -ms-flex-shrink: 8;
    flex-shrink: 8;
  }

  .tablet-shrink-9 {
    -webkit-flex-shrink: 9;
    -moz-flex-shrink: 9;
    -ms-flex-shrink: 9;
    flex-shrink: 9;
  }

  .tablet-shrink-10 {
    -webkit-flex-shrink: 10;
    -moz-flex-shrink: 10;
    -ms-flex-shrink: 10;
    flex-shrink: 10;
  }

  .tablet-align-self-flex-start {
    -webkit-align-self: flex-start;
    -moz-align-self: flex-start;
    -ms-align-self: flex-start;
    align-self: flex-start;
  }

  .tablet-align-self-flex-end {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-align-self: flex-end;
    align-self: flex-end;
  }

  .tablet-align-self-center {
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-align-self: center;
    align-self: center;
  }

  .tablet-align-self-baseline {
    -webkit-align-self: baseline;
    -moz-align-self: baseline;
    -ms-align-self: baseline;
    align-self: baseline;
  }

  .tablet-align-self-stretch {
    -webkit-align-self: stretch;
    -moz-align-self: stretch;
    -ms-align-self: stretch;
    align-self: stretch;
  }
}
@media (min-width: 1201px) {
  .desktop-icon-size-0 {
    font-size: 0px;
    line-height: 0px;
    height: 0px;
    width: 0px;
  }

  .desktop-icon-size-1 {
    font-size: 2px;
    line-height: 2px;
    height: 2px;
    width: 2px;
  }

  .desktop-icon-size-2 {
    font-size: 4px;
    line-height: 4px;
    height: 4px;
    width: 4px;
  }

  .desktop-icon-size-3 {
    font-size: 6px;
    line-height: 6px;
    height: 6px;
    width: 6px;
  }

  .desktop-icon-size-4 {
    font-size: 8px;
    line-height: 8px;
    height: 8px;
    width: 8px;
  }

  .desktop-icon-size-5 {
    font-size: 10px;
    line-height: 10px;
    height: 10px;
    width: 10px;
  }

  .desktop-icon-size-6 {
    font-size: 12px;
    line-height: 12px;
    height: 12px;
    width: 12px;
  }

  .desktop-icon-size-7 {
    font-size: 14px;
    line-height: 14px;
    height: 14px;
    width: 14px;
  }

  .desktop-icon-size-8 {
    font-size: 16px;
    line-height: 16px;
    height: 16px;
    width: 16px;
  }

  .desktop-icon-size-9 {
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: 18px;
  }

  .desktop-icon-size-10 {
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    width: 20px;
  }

  .desktop-icon-size-11 {
    font-size: 22px;
    line-height: 22px;
    height: 22px;
    width: 22px;
  }

  .desktop-icon-size-12 {
    font-size: 24px;
    line-height: 24px;
    height: 24px;
    width: 24px;
  }

  .desktop-icon-size-13 {
    font-size: 26px;
    line-height: 26px;
    height: 26px;
    width: 26px;
  }

  .desktop-icon-size-14 {
    font-size: 28px;
    line-height: 28px;
    height: 28px;
    width: 28px;
  }

  .desktop-icon-size-15 {
    font-size: 30px;
    line-height: 30px;
    height: 30px;
    width: 30px;
  }

  .desktop-icon-size-16 {
    font-size: 32px;
    line-height: 32px;
    height: 32px;
    width: 32px;
  }

  .desktop-icon-size-17 {
    font-size: 34px;
    line-height: 34px;
    height: 34px;
    width: 34px;
  }

  .desktop-icon-size-18 {
    font-size: 36px;
    line-height: 36px;
    height: 36px;
    width: 36px;
  }

  .desktop-icon-size-19 {
    font-size: 38px;
    line-height: 38px;
    height: 38px;
    width: 38px;
  }

  .desktop-icon-size-20 {
    font-size: 40px;
    line-height: 40px;
    height: 40px;
    width: 40px;
  }

  .desktop-icon-size-25 {
    font-size: 50px;
    line-height: 50px;
    height: 50px;
    width: 50px;
  }

  .desktop-text-size-0 {
    font-size: 0px;
  }

  .desktop-text-size-1 {
    font-size: 2px;
  }

  .desktop-text-size-2 {
    font-size: 4px;
  }

  .desktop-text-size-3 {
    font-size: 6px;
  }

  .desktop-text-size-4 {
    font-size: 8px;
  }

  .desktop-text-size-5 {
    font-size: 10px;
  }

  .desktop-text-size-6 {
    font-size: 12px;
  }

  .desktop-text-size-7 {
    font-size: 14px;
  }

  .desktop-text-size-8 {
    font-size: 16px;
  }

  .desktop-text-size-9 {
    font-size: 18px;
  }

  .desktop-text-size-10 {
    font-size: 20px;
  }

  .desktop-text-size-11 {
    font-size: 22px;
  }

  .desktop-text-size-12 {
    font-size: 24px;
  }

  .desktop-text-size-13 {
    font-size: 26px;
  }

  .desktop-text-size-14 {
    font-size: 28px;
  }

  .desktop-text-size-15 {
    font-size: 30px;
  }

  .desktop-text-size-16 {
    font-size: 32px;
  }

  .desktop-text-size-17 {
    font-size: 34px;
  }

  .desktop-text-size-18 {
    font-size: 36px;
  }

  .desktop-text-size-19 {
    font-size: 38px;
  }

  .desktop-text-size-20 {
    font-size: 40px;
  }

  .desktop-text-size-25 {
    font-size: 50px;
  }

  .desktop-w-0 {
    width: 0px;
  }

  .desktop-h-0 {
    height: 0px;
  }

  .desktop-w-1 {
    width: 4px;
  }

  .desktop-h-1 {
    height: 4px;
  }

  .desktop-w-2 {
    width: 8px;
  }

  .desktop-h-2 {
    height: 8px;
  }

  .desktop-w-3 {
    width: 12px;
  }

  .desktop-h-3 {
    height: 12px;
  }

  .desktop-w-4 {
    width: 16px;
  }

  .desktop-h-4 {
    height: 16px;
  }

  .desktop-w-5 {
    width: 20px;
  }

  .desktop-h-5 {
    height: 20px;
  }

  .desktop-w-6 {
    width: 24px;
  }

  .desktop-h-6 {
    height: 24px;
  }

  .desktop-w-7 {
    width: 28px;
  }

  .desktop-h-7 {
    height: 28px;
  }

  .desktop-w-8 {
    width: 32px;
  }

  .desktop-h-8 {
    height: 32px;
  }

  .desktop-w-9 {
    width: 36px;
  }

  .desktop-h-9 {
    height: 36px;
  }

  .desktop-w-10 {
    width: 40px;
  }

  .desktop-h-10 {
    height: 40px;
  }

  .desktop-w-11 {
    width: 44px;
  }

  .desktop-h-11 {
    height: 44px;
  }

  .desktop-w-12 {
    width: 48px;
  }

  .desktop-h-12 {
    height: 48px;
  }

  .desktop-w-13 {
    width: 52px;
  }

  .desktop-h-13 {
    height: 52px;
  }

  .desktop-w-14 {
    width: 56px;
  }

  .desktop-h-14 {
    height: 56px;
  }

  .desktop-w-15 {
    width: 60px;
  }

  .desktop-h-15 {
    height: 60px;
  }

  .desktop-w-16 {
    width: 64px;
  }

  .desktop-h-16 {
    height: 64px;
  }

  .desktop-w-17 {
    width: 68px;
  }

  .desktop-h-17 {
    height: 68px;
  }

  .desktop-w-18 {
    width: 72px;
  }

  .desktop-h-18 {
    height: 72px;
  }

  .desktop-w-19 {
    width: 76px;
  }

  .desktop-h-19 {
    height: 76px;
  }

  .desktop-w-20 {
    width: 80px;
  }

  .desktop-h-20 {
    height: 80px;
  }

  .desktop-w-25 {
    width: 100px;
  }

  .desktop-h-25 {
    height: 100px;
  }

  .desktop-m-0 {
    margin: 0px;
  }

  .desktop-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop-ml-0 {
    margin-left: 0px;
  }

  .desktop-mr-0 {
    margin-right: 0px;
  }

  .desktop-mb-0 {
    margin-bottom: 0px;
  }

  .desktop-mt-0 {
    margin-top: 0px;
  }

  .desktop-p-0 {
    padding: 0px;
  }

  .desktop-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .desktop-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .desktop-pl-0 {
    padding-left: 0px;
  }

  .desktop-pr-0 {
    padding-right: 0px;
  }

  .desktop-pb-0 {
    padding-bottom: 0px;
  }

  .desktop-pt-0 {
    padding-top: 0px;
  }

  .desktop-m-1 {
    margin: 4px;
  }

  .desktop-mx-1 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .desktop-my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .desktop-ml-1 {
    margin-left: 4px;
  }

  .desktop-mr-1 {
    margin-right: 4px;
  }

  .desktop-mb-1 {
    margin-bottom: 4px;
  }

  .desktop-mt-1 {
    margin-top: 4px;
  }

  .desktop-p-1 {
    padding: 4px;
  }

  .desktop-px-1 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .desktop-py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .desktop-pl-1 {
    padding-left: 4px;
  }

  .desktop-pr-1 {
    padding-right: 4px;
  }

  .desktop-pb-1 {
    padding-bottom: 4px;
  }

  .desktop-pt-1 {
    padding-top: 4px;
  }

  .desktop-m-2 {
    margin: 8px;
  }

  .desktop-mx-2 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .desktop-my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .desktop-ml-2 {
    margin-left: 8px;
  }

  .desktop-mr-2 {
    margin-right: 8px;
  }

  .desktop-mb-2 {
    margin-bottom: 8px;
  }

  .desktop-mt-2 {
    margin-top: 8px;
  }

  .desktop-p-2 {
    padding: 8px;
  }

  .desktop-px-2 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .desktop-py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .desktop-pl-2 {
    padding-left: 8px;
  }

  .desktop-pr-2 {
    padding-right: 8px;
  }

  .desktop-pb-2 {
    padding-bottom: 8px;
  }

  .desktop-pt-2 {
    padding-top: 8px;
  }

  .desktop-m-3 {
    margin: 12px;
  }

  .desktop-mx-3 {
    margin-left: 12px;
    margin-right: 12px;
  }

  .desktop-my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .desktop-ml-3 {
    margin-left: 12px;
  }

  .desktop-mr-3 {
    margin-right: 12px;
  }

  .desktop-mb-3 {
    margin-bottom: 12px;
  }

  .desktop-mt-3 {
    margin-top: 12px;
  }

  .desktop-p-3 {
    padding: 12px;
  }

  .desktop-px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }

  .desktop-py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .desktop-pl-3 {
    padding-left: 12px;
  }

  .desktop-pr-3 {
    padding-right: 12px;
  }

  .desktop-pb-3 {
    padding-bottom: 12px;
  }

  .desktop-pt-3 {
    padding-top: 12px;
  }

  .desktop-m-4 {
    margin: 16px;
  }

  .desktop-mx-4 {
    margin-left: 16px;
    margin-right: 16px;
  }

  .desktop-my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .desktop-ml-4 {
    margin-left: 16px;
  }

  .desktop-mr-4 {
    margin-right: 16px;
  }

  .desktop-mb-4 {
    margin-bottom: 16px;
  }

  .desktop-mt-4 {
    margin-top: 16px;
  }

  .desktop-p-4 {
    padding: 16px;
  }

  .desktop-px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .desktop-py-4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .desktop-pl-4 {
    padding-left: 16px;
  }

  .desktop-pr-4 {
    padding-right: 16px;
  }

  .desktop-pb-4 {
    padding-bottom: 16px;
  }

  .desktop-pt-4 {
    padding-top: 16px;
  }

  .desktop-m-5 {
    margin: 20px;
  }

  .desktop-mx-5 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .desktop-my-5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .desktop-ml-5 {
    margin-left: 20px;
  }

  .desktop-mr-5 {
    margin-right: 20px;
  }

  .desktop-mb-5 {
    margin-bottom: 20px;
  }

  .desktop-mt-5 {
    margin-top: 20px;
  }

  .desktop-p-5 {
    padding: 20px;
  }

  .desktop-px-5 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .desktop-py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .desktop-pl-5 {
    padding-left: 20px;
  }

  .desktop-pr-5 {
    padding-right: 20px;
  }

  .desktop-pb-5 {
    padding-bottom: 20px;
  }

  .desktop-pt-5 {
    padding-top: 20px;
  }

  .desktop-m-6 {
    margin: 24px;
  }

  .desktop-mx-6 {
    margin-left: 24px;
    margin-right: 24px;
  }

  .desktop-my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .desktop-ml-6 {
    margin-left: 24px;
  }

  .desktop-mr-6 {
    margin-right: 24px;
  }

  .desktop-mb-6 {
    margin-bottom: 24px;
  }

  .desktop-mt-6 {
    margin-top: 24px;
  }

  .desktop-p-6 {
    padding: 24px;
  }

  .desktop-px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .desktop-py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .desktop-pl-6 {
    padding-left: 24px;
  }

  .desktop-pr-6 {
    padding-right: 24px;
  }

  .desktop-pb-6 {
    padding-bottom: 24px;
  }

  .desktop-pt-6 {
    padding-top: 24px;
  }

  .desktop-m-7 {
    margin: 28px;
  }

  .desktop-mx-7 {
    margin-left: 28px;
    margin-right: 28px;
  }

  .desktop-my-7 {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .desktop-ml-7 {
    margin-left: 28px;
  }

  .desktop-mr-7 {
    margin-right: 28px;
  }

  .desktop-mb-7 {
    margin-bottom: 28px;
  }

  .desktop-mt-7 {
    margin-top: 28px;
  }

  .desktop-p-7 {
    padding: 28px;
  }

  .desktop-px-7 {
    padding-left: 28px;
    padding-right: 28px;
  }

  .desktop-py-7 {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .desktop-pl-7 {
    padding-left: 28px;
  }

  .desktop-pr-7 {
    padding-right: 28px;
  }

  .desktop-pb-7 {
    padding-bottom: 28px;
  }

  .desktop-pt-7 {
    padding-top: 28px;
  }

  .desktop-m-8 {
    margin: 32px;
  }

  .desktop-mx-8 {
    margin-left: 32px;
    margin-right: 32px;
  }

  .desktop-my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .desktop-ml-8 {
    margin-left: 32px;
  }

  .desktop-mr-8 {
    margin-right: 32px;
  }

  .desktop-mb-8 {
    margin-bottom: 32px;
  }

  .desktop-mt-8 {
    margin-top: 32px;
  }

  .desktop-p-8 {
    padding: 32px;
  }

  .desktop-px-8 {
    padding-left: 32px;
    padding-right: 32px;
  }

  .desktop-py-8 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .desktop-pl-8 {
    padding-left: 32px;
  }

  .desktop-pr-8 {
    padding-right: 32px;
  }

  .desktop-pb-8 {
    padding-bottom: 32px;
  }

  .desktop-pt-8 {
    padding-top: 32px;
  }

  .desktop-m-9 {
    margin: 36px;
  }

  .desktop-mx-9 {
    margin-left: 36px;
    margin-right: 36px;
  }

  .desktop-my-9 {
    margin-top: 36px;
    margin-bottom: 36px;
  }

  .desktop-ml-9 {
    margin-left: 36px;
  }

  .desktop-mr-9 {
    margin-right: 36px;
  }

  .desktop-mb-9 {
    margin-bottom: 36px;
  }

  .desktop-mt-9 {
    margin-top: 36px;
  }

  .desktop-p-9 {
    padding: 36px;
  }

  .desktop-px-9 {
    padding-left: 36px;
    padding-right: 36px;
  }

  .desktop-py-9 {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .desktop-pl-9 {
    padding-left: 36px;
  }

  .desktop-pr-9 {
    padding-right: 36px;
  }

  .desktop-pb-9 {
    padding-bottom: 36px;
  }

  .desktop-pt-9 {
    padding-top: 36px;
  }

  .desktop-m-10 {
    margin: 40px;
  }

  .desktop-mx-10 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .desktop-my-10 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .desktop-ml-10 {
    margin-left: 40px;
  }

  .desktop-mr-10 {
    margin-right: 40px;
  }

  .desktop-mb-10 {
    margin-bottom: 40px;
  }

  .desktop-mt-10 {
    margin-top: 40px;
  }

  .desktop-p-10 {
    padding: 40px;
  }

  .desktop-px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .desktop-py-10 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .desktop-pl-10 {
    padding-left: 40px;
  }

  .desktop-pr-10 {
    padding-right: 40px;
  }

  .desktop-pb-10 {
    padding-bottom: 40px;
  }

  .desktop-pt-10 {
    padding-top: 40px;
  }

  .desktop-m-11 {
    margin: 44px;
  }

  .desktop-mx-11 {
    margin-left: 44px;
    margin-right: 44px;
  }

  .desktop-my-11 {
    margin-top: 44px;
    margin-bottom: 44px;
  }

  .desktop-ml-11 {
    margin-left: 44px;
  }

  .desktop-mr-11 {
    margin-right: 44px;
  }

  .desktop-mb-11 {
    margin-bottom: 44px;
  }

  .desktop-mt-11 {
    margin-top: 44px;
  }

  .desktop-p-11 {
    padding: 44px;
  }

  .desktop-px-11 {
    padding-left: 44px;
    padding-right: 44px;
  }

  .desktop-py-11 {
    padding-top: 44px;
    padding-bottom: 44px;
  }

  .desktop-pl-11 {
    padding-left: 44px;
  }

  .desktop-pr-11 {
    padding-right: 44px;
  }

  .desktop-pb-11 {
    padding-bottom: 44px;
  }

  .desktop-pt-11 {
    padding-top: 44px;
  }

  .desktop-m-12 {
    margin: 48px;
  }

  .desktop-mx-12 {
    margin-left: 48px;
    margin-right: 48px;
  }

  .desktop-my-12 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .desktop-ml-12 {
    margin-left: 48px;
  }

  .desktop-mr-12 {
    margin-right: 48px;
  }

  .desktop-mb-12 {
    margin-bottom: 48px;
  }

  .desktop-mt-12 {
    margin-top: 48px;
  }

  .desktop-p-12 {
    padding: 48px;
  }

  .desktop-px-12 {
    padding-left: 48px;
    padding-right: 48px;
  }

  .desktop-py-12 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .desktop-pl-12 {
    padding-left: 48px;
  }

  .desktop-pr-12 {
    padding-right: 48px;
  }

  .desktop-pb-12 {
    padding-bottom: 48px;
  }

  .desktop-pt-12 {
    padding-top: 48px;
  }

  .desktop-m-13 {
    margin: 52px;
  }

  .desktop-mx-13 {
    margin-left: 52px;
    margin-right: 52px;
  }

  .desktop-my-13 {
    margin-top: 52px;
    margin-bottom: 52px;
  }

  .desktop-ml-13 {
    margin-left: 52px;
  }

  .desktop-mr-13 {
    margin-right: 52px;
  }

  .desktop-mb-13 {
    margin-bottom: 52px;
  }

  .desktop-mt-13 {
    margin-top: 52px;
  }

  .desktop-p-13 {
    padding: 52px;
  }

  .desktop-px-13 {
    padding-left: 52px;
    padding-right: 52px;
  }

  .desktop-py-13 {
    padding-top: 52px;
    padding-bottom: 52px;
  }

  .desktop-pl-13 {
    padding-left: 52px;
  }

  .desktop-pr-13 {
    padding-right: 52px;
  }

  .desktop-pb-13 {
    padding-bottom: 52px;
  }

  .desktop-pt-13 {
    padding-top: 52px;
  }

  .desktop-m-14 {
    margin: 56px;
  }

  .desktop-mx-14 {
    margin-left: 56px;
    margin-right: 56px;
  }

  .desktop-my-14 {
    margin-top: 56px;
    margin-bottom: 56px;
  }

  .desktop-ml-14 {
    margin-left: 56px;
  }

  .desktop-mr-14 {
    margin-right: 56px;
  }

  .desktop-mb-14 {
    margin-bottom: 56px;
  }

  .desktop-mt-14 {
    margin-top: 56px;
  }

  .desktop-p-14 {
    padding: 56px;
  }

  .desktop-px-14 {
    padding-left: 56px;
    padding-right: 56px;
  }

  .desktop-py-14 {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .desktop-pl-14 {
    padding-left: 56px;
  }

  .desktop-pr-14 {
    padding-right: 56px;
  }

  .desktop-pb-14 {
    padding-bottom: 56px;
  }

  .desktop-pt-14 {
    padding-top: 56px;
  }

  .desktop-m-15 {
    margin: 60px;
  }

  .desktop-mx-15 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .desktop-my-15 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .desktop-ml-15 {
    margin-left: 60px;
  }

  .desktop-mr-15 {
    margin-right: 60px;
  }

  .desktop-mb-15 {
    margin-bottom: 60px;
  }

  .desktop-mt-15 {
    margin-top: 60px;
  }

  .desktop-p-15 {
    padding: 60px;
  }

  .desktop-px-15 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .desktop-py-15 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .desktop-pl-15 {
    padding-left: 60px;
  }

  .desktop-pr-15 {
    padding-right: 60px;
  }

  .desktop-pb-15 {
    padding-bottom: 60px;
  }

  .desktop-pt-15 {
    padding-top: 60px;
  }

  .desktop-m-16 {
    margin: 64px;
  }

  .desktop-mx-16 {
    margin-left: 64px;
    margin-right: 64px;
  }

  .desktop-my-16 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .desktop-ml-16 {
    margin-left: 64px;
  }

  .desktop-mr-16 {
    margin-right: 64px;
  }

  .desktop-mb-16 {
    margin-bottom: 64px;
  }

  .desktop-mt-16 {
    margin-top: 64px;
  }

  .desktop-p-16 {
    padding: 64px;
  }

  .desktop-px-16 {
    padding-left: 64px;
    padding-right: 64px;
  }

  .desktop-py-16 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .desktop-pl-16 {
    padding-left: 64px;
  }

  .desktop-pr-16 {
    padding-right: 64px;
  }

  .desktop-pb-16 {
    padding-bottom: 64px;
  }

  .desktop-pt-16 {
    padding-top: 64px;
  }

  .desktop-m-17 {
    margin: 68px;
  }

  .desktop-mx-17 {
    margin-left: 68px;
    margin-right: 68px;
  }

  .desktop-my-17 {
    margin-top: 68px;
    margin-bottom: 68px;
  }

  .desktop-ml-17 {
    margin-left: 68px;
  }

  .desktop-mr-17 {
    margin-right: 68px;
  }

  .desktop-mb-17 {
    margin-bottom: 68px;
  }

  .desktop-mt-17 {
    margin-top: 68px;
  }

  .desktop-p-17 {
    padding: 68px;
  }

  .desktop-px-17 {
    padding-left: 68px;
    padding-right: 68px;
  }

  .desktop-py-17 {
    padding-top: 68px;
    padding-bottom: 68px;
  }

  .desktop-pl-17 {
    padding-left: 68px;
  }

  .desktop-pr-17 {
    padding-right: 68px;
  }

  .desktop-pb-17 {
    padding-bottom: 68px;
  }

  .desktop-pt-17 {
    padding-top: 68px;
  }

  .desktop-m-18 {
    margin: 72px;
  }

  .desktop-mx-18 {
    margin-left: 72px;
    margin-right: 72px;
  }

  .desktop-my-18 {
    margin-top: 72px;
    margin-bottom: 72px;
  }

  .desktop-ml-18 {
    margin-left: 72px;
  }

  .desktop-mr-18 {
    margin-right: 72px;
  }

  .desktop-mb-18 {
    margin-bottom: 72px;
  }

  .desktop-mt-18 {
    margin-top: 72px;
  }

  .desktop-p-18 {
    padding: 72px;
  }

  .desktop-px-18 {
    padding-left: 72px;
    padding-right: 72px;
  }

  .desktop-py-18 {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .desktop-pl-18 {
    padding-left: 72px;
  }

  .desktop-pr-18 {
    padding-right: 72px;
  }

  .desktop-pb-18 {
    padding-bottom: 72px;
  }

  .desktop-pt-18 {
    padding-top: 72px;
  }

  .desktop-m-19 {
    margin: 76px;
  }

  .desktop-mx-19 {
    margin-left: 76px;
    margin-right: 76px;
  }

  .desktop-my-19 {
    margin-top: 76px;
    margin-bottom: 76px;
  }

  .desktop-ml-19 {
    margin-left: 76px;
  }

  .desktop-mr-19 {
    margin-right: 76px;
  }

  .desktop-mb-19 {
    margin-bottom: 76px;
  }

  .desktop-mt-19 {
    margin-top: 76px;
  }

  .desktop-p-19 {
    padding: 76px;
  }

  .desktop-px-19 {
    padding-left: 76px;
    padding-right: 76px;
  }

  .desktop-py-19 {
    padding-top: 76px;
    padding-bottom: 76px;
  }

  .desktop-pl-19 {
    padding-left: 76px;
  }

  .desktop-pr-19 {
    padding-right: 76px;
  }

  .desktop-pb-19 {
    padding-bottom: 76px;
  }

  .desktop-pt-19 {
    padding-top: 76px;
  }

  .desktop-m-20 {
    margin: 80px;
  }

  .desktop-mx-20 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .desktop-my-20 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .desktop-ml-20 {
    margin-left: 80px;
  }

  .desktop-mr-20 {
    margin-right: 80px;
  }

  .desktop-mb-20 {
    margin-bottom: 80px;
  }

  .desktop-mt-20 {
    margin-top: 80px;
  }

  .desktop-p-20 {
    padding: 80px;
  }

  .desktop-px-20 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .desktop-py-20 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .desktop-pl-20 {
    padding-left: 80px;
  }

  .desktop-pr-20 {
    padding-right: 80px;
  }

  .desktop-pb-20 {
    padding-bottom: 80px;
  }

  .desktop-pt-20 {
    padding-top: 80px;
  }

  .desktop-m-25 {
    margin: 100px;
  }

  .desktop-mx-25 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .desktop-my-25 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .desktop-ml-25 {
    margin-left: 100px;
  }

  .desktop-mr-25 {
    margin-right: 100px;
  }

  .desktop-mb-25 {
    margin-bottom: 100px;
  }

  .desktop-mt-25 {
    margin-top: 100px;
  }

  .desktop-p-25 {
    padding: 100px;
  }

  .desktop-px-25 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .desktop-py-25 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .desktop-pl-25 {
    padding-left: 100px;
  }

  .desktop-pr-25 {
    padding-right: 100px;
  }

  .desktop-pb-25 {
    padding-bottom: 100px;
  }

  .desktop-pt-25 {
    padding-top: 100px;
  }

  .desktop-d-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
  }

  .desktop-d-block {
    display: -webkit-block;
    display: -moz-block;
    display: -ms-blockbox;
    display: -ms-block;
    display: block;
  }

  .desktop-d-grid {
    display: -webkit-grid;
    display: -moz-grid;
    display: -ms-gridbox;
    display: -ms-grid;
    display: grid;
  }

  .desktop-d-inline {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: -ms-inline-flex;
    display: inline-flex;
  }

  .desktop-d-none {
    display: -webkit-none;
    display: -moz-none;
    display: -ms-nonebox;
    display: -ms-none;
    display: none;
  }

  .desktop-direction-row {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .desktop-directionrow-reverse {
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .desktop-direction-column {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .desktop-direction-column-reverse {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .desktop-wrap-wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .desktop-wrap-nowrap {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .desktop-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -moz-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }

  .desktop-justify-flex-start {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }

  .desktop-justify-flex-end {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
  }

  .desktop-justify-center {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }

  .desktop-justify-space-between {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
  }

  .desktop-justify-space-around {
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
  }

  .desktop-justify-self-flex-start {
    -webkit-justify-self: flex-start;
    -moz-justify-self: flex-start;
    -ms-justify-self: flex-start;
    justify-self: flex-start;
  }

  .desktop-justify-self-flex-end {
    -webkit-justify-self: flex-end;
    -moz-justify-self: flex-end;
    -ms-justify-self: flex-end;
    justify-self: flex-end;
  }

  .desktop-justify-self-center {
    -webkit-justify-self: center;
    -moz-justify-self: center;
    -ms-justify-self: center;
    justify-self: center;
  }

  .desktop-justify-self-space-between {
    -webkit-justify-self: space-between;
    -moz-justify-self: space-between;
    -ms-justify-self: space-between;
    justify-self: space-between;
  }

  .desktop-justify-self-space-around {
    -webkit-justify-self: space-around;
    -moz-justify-self: space-around;
    -ms-justify-self: space-around;
    justify-self: space-around;
  }

  .desktop-align-content-flex-start {
    -webkit-align-content: flex-start;
    -moz-align-content: flex-start;
    -ms-align-content: flex-start;
    align-content: flex-start;
  }

  .desktop-align-content-flex-end {
    -webkit-align-content: flex-end;
    -moz-align-content: flex-end;
    -ms-align-content: flex-end;
    align-content: flex-end;
  }

  .desktop-align-content-center {
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
  }

  .desktop-align-content-space-between {
    -webkit-align-content: space-between;
    -moz-align-content: space-between;
    -ms-align-content: space-between;
    align-content: space-between;
  }

  .desktop-align-content-space-around {
    -webkit-align-content: space-around;
    -moz-align-content: space-around;
    -ms-align-content: space-around;
    align-content: space-around;
  }

  .desktop-align-content-stretch {
    -webkit-align-content: stretch;
    -moz-align-content: stretch;
    -ms-align-content: stretch;
    align-content: stretch;
  }

  .desktop-align-items-flex-start {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }

  .desktop-align-items-flex-end {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
  }

  .desktop-align-items-center {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }

  .desktop-align-items-baseline {
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
  }

  .desktop-align-items-stretch {
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
  }

  .desktop-order-0 {
    -webkit-order: 0;
    -moz-order: 0;
    -ms-order: 0;
    order: 0;
  }

  .desktop-order-1 {
    -webkit-order: 1;
    -moz-order: 1;
    -ms-order: 1;
    order: 1;
  }

  .desktop-order-2 {
    -webkit-order: 2;
    -moz-order: 2;
    -ms-order: 2;
    order: 2;
  }

  .desktop-order-3 {
    -webkit-order: 3;
    -moz-order: 3;
    -ms-order: 3;
    order: 3;
  }

  .desktop-order-4 {
    -webkit-order: 4;
    -moz-order: 4;
    -ms-order: 4;
    order: 4;
  }

  .desktop-order-5 {
    -webkit-order: 5;
    -moz-order: 5;
    -ms-order: 5;
    order: 5;
  }

  .desktop-order-6 {
    -webkit-order: 6;
    -moz-order: 6;
    -ms-order: 6;
    order: 6;
  }

  .desktop-order-7 {
    -webkit-order: 7;
    -moz-order: 7;
    -ms-order: 7;
    order: 7;
  }

  .desktop-order-8 {
    -webkit-order: 8;
    -moz-order: 8;
    -ms-order: 8;
    order: 8;
  }

  .desktop-order-9 {
    -webkit-order: 9;
    -moz-order: 9;
    -ms-order: 9;
    order: 9;
  }

  .desktop-order-10 {
    -webkit-order: 10;
    -moz-order: 10;
    -ms-order: 10;
    order: 10;
  }

  .desktop-grow-0 {
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-grow: 0;
    flex-grow: 0;
  }

  .desktop-grow-1 {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
  }

  .desktop-grow-2 {
    -webkit-flex-grow: 2;
    -moz-flex-grow: 2;
    -ms-flex-grow: 2;
    flex-grow: 2;
  }

  .desktop-grow-3 {
    -webkit-flex-grow: 3;
    -moz-flex-grow: 3;
    -ms-flex-grow: 3;
    flex-grow: 3;
  }

  .desktop-grow-4 {
    -webkit-flex-grow: 4;
    -moz-flex-grow: 4;
    -ms-flex-grow: 4;
    flex-grow: 4;
  }

  .desktop-grow-5 {
    -webkit-flex-grow: 5;
    -moz-flex-grow: 5;
    -ms-flex-grow: 5;
    flex-grow: 5;
  }

  .desktop-grow-6 {
    -webkit-flex-grow: 6;
    -moz-flex-grow: 6;
    -ms-flex-grow: 6;
    flex-grow: 6;
  }

  .desktop-grow-7 {
    -webkit-flex-grow: 7;
    -moz-flex-grow: 7;
    -ms-flex-grow: 7;
    flex-grow: 7;
  }

  .desktop-grow-8 {
    -webkit-flex-grow: 8;
    -moz-flex-grow: 8;
    -ms-flex-grow: 8;
    flex-grow: 8;
  }

  .desktop-grow-9 {
    -webkit-flex-grow: 9;
    -moz-flex-grow: 9;
    -ms-flex-grow: 9;
    flex-grow: 9;
  }

  .desktop-grow-10 {
    -webkit-flex-grow: 10;
    -moz-flex-grow: 10;
    -ms-flex-grow: 10;
    flex-grow: 10;
  }

  .desktop-shrink-0 {
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
  }

  .desktop-shrink-1 {
    -webkit-flex-shrink: 1;
    -moz-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
  }

  .desktop-shrink-2 {
    -webkit-flex-shrink: 2;
    -moz-flex-shrink: 2;
    -ms-flex-shrink: 2;
    flex-shrink: 2;
  }

  .desktop-shrink-3 {
    -webkit-flex-shrink: 3;
    -moz-flex-shrink: 3;
    -ms-flex-shrink: 3;
    flex-shrink: 3;
  }

  .desktop-shrink-4 {
    -webkit-flex-shrink: 4;
    -moz-flex-shrink: 4;
    -ms-flex-shrink: 4;
    flex-shrink: 4;
  }

  .desktop-shrink-5 {
    -webkit-flex-shrink: 5;
    -moz-flex-shrink: 5;
    -ms-flex-shrink: 5;
    flex-shrink: 5;
  }

  .desktop-shrink-6 {
    -webkit-flex-shrink: 6;
    -moz-flex-shrink: 6;
    -ms-flex-shrink: 6;
    flex-shrink: 6;
  }

  .desktop-shrink-7 {
    -webkit-flex-shrink: 7;
    -moz-flex-shrink: 7;
    -ms-flex-shrink: 7;
    flex-shrink: 7;
  }

  .desktop-shrink-8 {
    -webkit-flex-shrink: 8;
    -moz-flex-shrink: 8;
    -ms-flex-shrink: 8;
    flex-shrink: 8;
  }

  .desktop-shrink-9 {
    -webkit-flex-shrink: 9;
    -moz-flex-shrink: 9;
    -ms-flex-shrink: 9;
    flex-shrink: 9;
  }

  .desktop-shrink-10 {
    -webkit-flex-shrink: 10;
    -moz-flex-shrink: 10;
    -ms-flex-shrink: 10;
    flex-shrink: 10;
  }

  .desktop-align-self-flex-start {
    -webkit-align-self: flex-start;
    -moz-align-self: flex-start;
    -ms-align-self: flex-start;
    align-self: flex-start;
  }

  .desktop-align-self-flex-end {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-align-self: flex-end;
    align-self: flex-end;
  }

  .desktop-align-self-center {
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-align-self: center;
    align-self: center;
  }

  .desktop-align-self-baseline {
    -webkit-align-self: baseline;
    -moz-align-self: baseline;
    -ms-align-self: baseline;
    align-self: baseline;
  }

  .desktop-align-self-stretch {
    -webkit-align-self: stretch;
    -moz-align-self: stretch;
    -ms-align-self: stretch;
    align-self: stretch;
  }
}