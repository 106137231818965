.default {
  height: 48px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px !important;
}

.medium {
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  min-width: 90px !important;
  border-radius: 4px !important;
}

.link {
  font-family: "Roboto";
  font-weight: 500;
  color: $accent-color;
}

button.mat-ocean{
  background: $ocean;
  color: white;
}

button.mat-accent-green{
  background: $accent-green;
  color: white;
}