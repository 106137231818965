@import "variables";

.table {
  border-collapse: collapse;
  & td {
    padding: 15px;
  }
  & thead td {
    background-color: #54585d;
    color: $white;
    font-weight: bold;
    font-size: 13px;
    border: 1px solid #54585d;
  }
  & tbody td {
    color: #636363;
    border: 1px solid #dddfe1;
  }
  & tbody tr {
    background-color: #f9fafb;
  }
  & tbody tr:nth-child(odd) {
    background-color: $white;
  }
}

mat-table.table {
  flex: 1;
  width: auto;
  overflow-y: auto;
  background-color: white;

  .table__header {
    &-row {
      border: 0;
    }

    &-cell {
      font-size: 1rem;
      font-weight: normal;
      color: var(--font-color);
    }
  }

  .table__cell {
    font-size: 0.95rem;
    color: rgba(0, 0, 0, 0.6);
  }

  .table__row {
    border: 0;
    transition: background-color 0.23s ease;

    &--link {
      cursor: pointer;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
}

.mat-sort-header {
  &.center {
    .mat-sort-header-container {
      justify-content: center;
      padding-left: 12px;
    }
  }
}

mat-table {
  &.clear-table {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      background-color: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e0846b;
    }

    mat-row,
    mat-header-row,
    mat-footer-row {
      border-bottom-width: 0px !important;
    }

    mat-header-cell {
      &:last-child {
        padding-right: 0;
      }

      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.87);
    }

    mat-cell {
      &:last-child {
        padding-right: 0;
        flex-direction: reverse;
        justify-content: center;
      }
    }
  }
}

.circle-status {
  width: 24px;
  height: 24px;
  border-radius: 100%;

  &-green {
    background-color: #8be37c;
  }

  &-red {
    background: #F7AEA4;
  }
}
